/* eslint-disable no-mixed-spaces-and-tabs */
export function quickSort<T>(
	arr: Array<T>,
	compare: (a: T, b: T) => boolean,
	left = 0,
	right = arr.length - 1
  ): Array<T> {
	let i = left;
	let j = right;
	let tmp;
	const pivotidx = Math.floor((left + right) / 2);
	const pivot = arr[pivotidx];
  
	while (i <= j) {
	  while (compare(arr[i], pivot)) {
		i++;
	  }
	  while (compare(pivot, arr[j])) {
		j--;
	  }
	  if (i <= j) {
		tmp = arr[i];
		arr[i] = arr[j];
		arr[j] = tmp;
		i++;
		j--;
	  }
	}
  
	/* recursion */
	if (left < j) {
	  quickSort(arr, compare, left, j);
	}
	if (i < right) {
	  quickSort(arr, compare, i, right);
	}
  
	return arr;
  }