import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";
import { Attack } from "@blindspot/bspot-api-lib";

interface AttackErrCardProps {
  attacks: Attack[];
}

export default function AttackErrCard({ attacks }: AttackErrCardProps) {
  const { t } = useTranslation("index", { keyPrefix: "cards" });
  const loading = false;

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.mitigations.title")}
      h5={`${attacks.length} ${t("under_attack.mitigations.mitigation", { count: attacks.length })}`}
      icon={<ShieldOutlinedIcon color="error" fontSize="large" />}
    >
      <CustomCardAction linkToIdElement={{ text: t("common.show_all"), href: "#mitigations", color: "text.primary" }} />
    </TopCard>
  );
}
