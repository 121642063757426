import { SxProps, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Time } from "./TrafficGraphLine";
import { Interval } from "./TrafficGraph";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface TabChangeTimeProps {
  time: Time;
  sxItem?: SxProps;
  sx?: SxProps;
  onChange: (value: Time) => void;
}
export type Ref = {
  backZoom(): void;
};

const TabChangeTime = forwardRef<Ref, TabChangeTimeProps>(({ sxItem, onChange, time, sx: sxParent }, ref) => {
  const [interval, setInterval] = useState<Interval[] | undefined>(typeof time !== "string" ? time : undefined);

  useImperativeHandle(ref, () => ({
    backZoom() {
      if (time instanceof Array) {
        if (time.length !== 1) {
          onChange(time.slice(0, -1));
          return;
        }
        onChange("10 MIN");
        setInterval(undefined);
      }
    },
  }));

  useEffect(() => {
    if (typeof time !== "string") {
      setInterval(time);
    }
  }, [time]);

  const { t } = useTranslation("components", { keyPrefix: "tab_change_time" });

  if (interval || typeof time !== "string") {
    const TIME: Array<Time> = [interval || time, "10 MIN", "HOUR", "DAY", "WEEK", "MONTH"];
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
      onChange(TIME[newValue]);
    };
    const from = interval
      ? interval[interval.length - 1].from
      : (time as Interval[])[(time as Interval[]).length - 1].from;
    const to = interval ? interval[interval.length - 1].to : (time as Interval[])[(time as Interval[]).length - 1].to;

    return (
      <Tabs
        sx={{ minHeight: "2.5rem", width: "100%", ...sxParent }}
        value={TIME.findIndex(value => value === time || (typeof value !== "string" && typeof time !== "string"))}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="print-hide"
      >
        <Tab
          sx={{ ...sxItem, ...{ minWidth: "12rem" } }}
          label={`${new Date(from).toLocaleTimeString([], {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })} - ${new Date(to).toLocaleTimeString([], {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}`}
        />
        <Tab sx={sxItem} label={t("10_min")} />
        <Tab sx={sxItem} label={t("hour")} />
        <Tab sx={sxItem} label={t("day")} />
        <Tab sx={sxItem} label={t("week")} />
        <Tab sx={sxItem} label={t("month")} />
        {/* <Box component={Tab} sx={{ position: "absolute", zIndex: -1000, width: "1px", left: -100 }} /> */}
      </Tabs>
    );
  } else {
    const TIME: Array<Time> = ["10 MIN", "HOUR", "DAY", "WEEK", "MONTH"];
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
      onChange(TIME[newValue]);
    };
    return (
      <Tabs
        sx={{ minHeight: "2.5rem", width: "100%", ...sxParent }}
        value={TIME.findIndex(value => value === time)}
        onChange={handleChange}
        aria-label="basic tabs example"
        className="print-hide"
      >
        <Tab sx={sxItem} label={t("10_min")} />
        <Tab sx={sxItem} label={t("hour")} />
        <Tab sx={sxItem} label={t("day")} />
        <Tab sx={sxItem} label={t("week")} />
        <Tab sx={sxItem} label={t("month")} />
        {/* <Box component={Tab} sx={{ position: "absolute", zIndex: -1000, width: "1px", left: -100 }} /> */}
      </Tabs>
    );
  }
});

TabChangeTime.displayName = "TabChangeTime";

export default TabChangeTime;
