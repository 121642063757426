import { useEffect } from "react";

export const usePrintBackground = (theme: any) => {
  useEffect(() => {
    if (theme.palette.mode === "dark") {
      document.body.classList.remove("print");
      document.body.classList.add("print-dark");
      document.documentElement.classList.add("print-dark");
    } else {
      document.body.classList.add("print");
      document.body.classList.remove("print-dark");
      document.documentElement.classList.remove("print-dark");
    }
  }, [theme.palette.mode]);
};
