import { Button, ButtonGroup, SxProps} from "@mui/material";

interface ButtonChangeStatusProps<L extends string, R extends string> {
    currentName: string;
    leftName: L;
    rightName: R;
    sx?: SxProps;
    onChange: (value: L | R) => void;
}

export default function ButtonChangeStatus<L extends string, R extends string>({currentName, leftName, rightName, sx, onChange}: ButtonChangeStatusProps<L, R>) {

  return (
    <ButtonGroup
      sx={sx}
    >
      <Button onClick={() => onChange(leftName)} variant={currentName === leftName ? "contained" : "outlined"}>{leftName}</Button>
      <Button onClick={() => onChange(rightName)} variant={currentName === rightName ? "contained" : "outlined"}>{rightName}</Button>
    </ButtonGroup>
  );
}
