import { Time } from "../components/TrafficGraphLine";

export const getShiftedInterval = (minutes: number, shift?: number) => {
  const to = new Date();
  shift && to.setMilliseconds(to.getMilliseconds() - shift);

  const from = new Date();
  from.setMinutes(to.getMinutes() - minutes);
  shift && from.setMilliseconds(from.getMilliseconds() - shift);


  return { to, from };
};

export const getTimeFrame = (timeFrame: Time) => {

  switch (timeFrame) {
    case "10 MIN":
      return getShiftedInterval(10, 10 * 1000);
    case "HOUR":
      return getShiftedInterval(60);
    case "DAY":
      return getShiftedInterval(1440);
    case "WEEK":
      return getShiftedInterval(10080);
    case "MONTH":
      return getShiftedInterval(40320);
  }
  return timeFrame[timeFrame.length - 1];
};
