import { Box, Divider, Drawer, Toolbar, useTheme, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Link from "../providers/router/Link";

interface NotificationDrawerProps {
  drawer: boolean;
}

interface Notifications {
  state: "error" | "warning";
  when: number;
  linkHref: string;
  linkText: string;
  header: string;
  detail?: string;
}

const notifications: Notifications[] = [
  {
    state: "error",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "DDoS Attack Alert (151323)",
    detail: "An attack was automatically detected and mitigated.",
  },
  {
    state: "warning",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "DDoS Attack mitigated for public IP (153462)",
  },
  {
    state: "warning",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "Scheduled maintenance: Blindspot service update on Saturday 08/12/2022 at 22:00 UTC",
    detail:
      "Due to scheduled maintenance activity, Blindspot dashboard will not be available on 8th December 2022 from 22:00 to 23:00 UTC. Regret the inconvenience caused.",
  },
  {
    state: "warning",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "DDoS Attack mitigated for public IP (153462)",
  },
  {
    state: "warning",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "DDoS Attack mitigated for public IP (153462)",
  },
  {
    state: "error",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "DDoS Attack Alert (151323)",
    detail: "An attack was automatically detected and mitigated.",
  },
  {
    state: "error",
    when: 2123,
    linkHref: "/attack-detail/0/false",
    linkText: "Attack detail",
    header: "DDoS Attack Alert (151323)",
    detail: "An attack was automatically detected and mitigated.",
  },
];

export default function NotificationDrawer({ drawer }: NotificationDrawerProps) {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <Drawer
          variant="persistent"
          anchor={"right"}
          open={drawer}
          sx={{
            flexShrink: 0,
            ["& .MuiDrawer-paper"]: { boxSizing: "border-box" },
          }}
          PaperProps={{
            sx: {
              backgroundColor: (theme.palette.mode === "dark" && "#262527") || "",
            },
          }}
        >
          <Box sx={{ overflow: "auto" }} data-testid={"notification-menu"}>
            <Toolbar />
            <Divider />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingY: "1.5rem",
                paddingX: "1rem",
              }}
            >
              <Typography variant="h6">Notifications</Typography>
              <Link href="/settings/notifications">Settings</Link>
            </Box>
            <Divider />
            {notifications.map((notification, key) => (
              <Box key={key}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: "1.5rem",
                    paddingY: "1rem",
                  }}
                >
                  {notification.state === "error" ? (
                    <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} />
                  ) : (
                    <WarningAmberIcon sx={{ color: theme.palette.warning.main }} />
                  )}
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box maxWidth="13rem" marginX="0.5rem">
                      <Typography variant="body2">{notification.header}</Typography>
                      {notification.detail && (
                        <Typography
                          component="p"
                          lineHeight="18px"
                          fontWeight="400"
                          fontSize="12"
                          variant="caption"
                          color={theme.palette.text.secondary}
                        >
                          {notification.detail}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <Typography fontWeight="400" fontSize="12" variant="caption" color={theme.palette.text.secondary}>
                        {notification.when}
                      </Typography>
                      <Link href={notification.linkHref}>{notification.linkText}</Link>
                    </Box>
                  </Box>
                </Box>
                {notifications.length === key + 1 ? <Divider /> : <Divider variant="middle" />}
              </Box>
            ))}
          </Box>
        </Drawer>
      </Box>
    </>
  );
}
