import Graphs from "./Graphs";
import PageTitle from "./PageTitle";
import AttackHistory from "./AttackHistory";
import ReceivedIPPrefixes from "./ReceivedIPPrefixes";
import Connections from "./Connections";
import CustomerIPPrefixes from "./CustomerIPPrefixes";
import ServiceStatus from "./ServiceStatus";
import IncomingSflowSamples from "./IncomingSflowSamples";
import ServiceOutages from "./ServiceOutages";
import ScheduledMaintenance from "./ScheduledMaintenance";
import { Breadcrumb } from "../../components/PageTitle";
import HomeIcon from "@mui/icons-material/Home";
import { Button } from "@mui/material";

export default function Index() {
  return (
    <>
      <PageTitle />
      <Graphs />
      <AttackHistory />
      {/* <ServiceStatus /> */}
      {/* <ServiceOutages /> */}
      {/* <ScheduledMaintenance /> */}
      <Connections />
      <ReceivedIPPrefixes />
      <CustomerIPPrefixes />
      {/* <IncomingSflowSamples /> */}
    </>
  );
}

export const index: Breadcrumb = {
  name: "Dashboard",
  href: "/",
  icon: <HomeIcon fontSize="inherit" />,
};
