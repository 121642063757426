import { Box, CardActions } from "@mui/material";
import { useTheme } from "@mui/material";
import { alpha } from "@mui/material";
import TopCard from "./TopCard";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { useApi } from "@blindspot/bspot-api-lib";
import { useTranslation } from "react-i18next";
import useReportError from "../hooks/useReportError";

function calculatePercent(peak_4: number, peak_6: number, bs_limit: number) {
  const percent = ((peak_4 + peak_6) / (bs_limit || 0)) * 100;
  return percent > 100 ? 100 : percent;
}

export default function AttackErrCard() {
  const { t, i18n } = useTranslation("index", { keyPrefix: "cards" });
  const theme = useTheme();
  const api = useRecoilValue(apiClient);
  const service = useService();
  const [ipv4, ipv4Err, ipv4Loading] = useApi([
    api.get_traffic(),
    { service, ipv: "ipv4", units: "l3bps", interval: "minute" },
  ]);
  const [ipv6, ipv6Err, ipv6Loading] = useApi([
    api.get_traffic(),
    { service, ipv: "ipv6", units: "l3bps", interval: "minute" },
  ]);

  const [connection, connectionErr, connectionLoading] = useApi([api.get_bgp_sessions(), service]);
  const [service_data, serviceErr, serviceLoading] = useApi([api.get_service(), service]);
  useReportError(
    [ipv4Err, ipv6Err, connectionErr, serviceErr],
    ["get_traffic", "get_traffic", "get_bgp_connections", "get_tenant"]
  );

  const warn: number =
    connection?.bgp_connections.filter(connection => !connection.session_status.status.includes("Established"))
      .length || 0;

  const peak_4 = ipv4?.find(d => d.type === "clean")?.max;
  const peak_6 = ipv6?.find(d => d.type === "clean")?.max;

  const bs_limit = service_data?.sp_max_clean_traffic;
  const loading = ipv4Loading && ipv6Loading && connectionLoading && serviceLoading;

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.bandwidth_use.title")}
      h5={`${
        peak_4 !== undefined && peak_6 !== undefined
          ? Math.floor(Math.max(0, peak_4 + peak_6) / 1000000).toLocaleString(i18n.language)
          : ""
      } ${
        bs_limit ? (bs_limit === -1 ? "" : `/ ${Math.floor(bs_limit || 0).toLocaleString(i18n.language)} `) : ""
      }Mbps`}
      dotStatuses={[{ name: `${warn} connections down`, state: warn > 0 ? "err" : "ok" }]}
    >
      <CardActions sx={{ paddingX: "1rem", paddingY: "1rem" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%", backgroundColor: alpha(theme.palette.error.main, 0.08), borderRadius: "4px" }}>
            <Box
              sx={{
                width:
                  (peak_4 !== undefined && peak_6 !== undefined && service_data
                    ? calculatePercent(peak_4, peak_6, bs_limit || 0).toString()
                    : "0") + "%",
                height: "4px",
                backgroundColor: theme.palette.error.main,
                borderRadius: "4px",
              }}
            />
          </Box>
        </Box>
      </CardActions>
    </TopCard>
  );
}
