import {
  alpha,
  AppBar,
  Avatar,
  Box,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ModeToggleButton from "../theme/ModeToggleButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilState, useRecoilValue } from "recoil";
import { apiClient, serviceState } from "../state/atoms";
import { useApi } from "@blindspot/bspot-api-lib";
import NotificationDrawer from "./NotificationDrawer";
import React, { useState } from "react";
import useReportError from "../hooks/useReportError";
import { useTranslation } from "react-i18next";
import BlindspotAvatar from "./BlindspotAvatar";
import MenuDrawer from "./MenuDrawer";
import { trackGaEvent } from "../utils/trackGaEvent";

export default function ButtonAppBar() {
  const theme = useTheme();
  const [tenant, setTenant] = useRecoilState(serviceState);
  const [drawer, _setDrawer] = useState(false);
  const api = useRecoilValue(apiClient);
  const [user, error, isLoading] = useApi(api.get_whoami());
  const [services, errorServices, loading] = useApi(api.get_services_list_simple());
  useReportError(error, "get_whoami");
  const { t } = useTranslation("components", { keyPrefix: "appbar", useSuspense: false });

  return (
    <Box className="print-hide">
      <Box sx={{ flexGrow: 1, position: "fixed", zIndex: 1000 }}>
        <AppBar
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.background.paper,
            flexGrow: 1,
          }}
          color="inherit"
          position="fixed"
        >
          <Toolbar>
            <Drawer />
            <Box sx={{ minWidth: 120, flexGrow: 1 }}>
              <FormControl size="small">
                {errorServices || loading ? (
                  <Skeleton variant="rounded" width={181} height={40} />
                ) : (
                  <Select
                    sx={{ maxHeight: "2.5rem" }}
                    id="app-bar-select"
                    value={tenant}
                    onChange={e => setTenant(e.target.value as number)}
                  >
                    {services.services.map(service => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
            <Box sx={{ width: "32.3rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Box width={"6rem"} sx={{ display: "flex", alignItems: "center" }}>
                {/*<SupportIcon sx={{ marginRight: '8px', color: alpha(theme.palette.text.secondary, 0.56) }} />
              <Typography variant="body2">
                Help
              </Typography>*/}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon sx={{ color: alpha(theme.palette.text.secondary, 0.56) }} />
                <Typography sx={{ marginLeft: "8px" }} variant="body2">
                  {t("hotline")}
                </Typography>
                <Link
                  href="tel:+44 20 3322 4442"
                  sx={{ marginLeft: "1rem", textDecoration: "none" }}
                  color="inherit"
                  variant="subtitle2"
                  onClick={() => trackGaEvent("custom_call-click")}
                >
                  +44 20 3322 4442
                </Link>
              </Box>
              <Box
                sx={{
                  minWidth: 110,
                  /*minWidth: 170,*/ display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: "1rem",
                }}
              >
                <ModeToggleButton />
                {/*
                <IconButton onClick={() => setDrawer(!drawer)}>
                  <NotificationsIcon sx={{ color: alpha(theme.palette.text.secondary, 0.56) }} />
                </IconButton>*/}
                {/*
                  * <IconButton onClick={() => navigate("/settings/account")}>
                  <SettingsIcon sx={{ color: alpha(theme.palette.text.secondary, 0.56) }} />
                </IconButton>*/}
                {isLoading || error ? (
                  <Skeleton variant="circular">
                    <Avatar />
                  </Skeleton>
                ) : (
                  <BlindspotAvatar given_name={user?.given_name} family_name={user?.family_name} email={user?.email} />
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <NotificationDrawer drawer={drawer} />
    </Box>
  );
}

function Drawer() {
  const [menuDrawer, setMenuDrawer] = useState(false);
  const theme = useTheme();

  return (
    <>
      <IconButton
        onClick={() => setMenuDrawer(!menuDrawer)}
        size="medium"
        edge="start"
        color="default"
        aria-label="menu"
        sx={{
          mr: 2,
          color: alpha(theme.palette.text.secondary, 0.7),
          display: { xxl: "none", xs: "inline-flex" },
        }}
      >
        <MenuIcon />
      </IconButton>
      <MenuDrawer drawer={menuDrawer} setDrawer={setMenuDrawer} />
    </>
  );
}
