import { RecoilRoot } from "recoil";
import React from "react";

import Main from "./Main";
import OpenIDGuard from "./providers/OpenIDGuard";

import "./styles/app.scss";
import CssBaseline from "@mui/material/CssBaseline";
import AppThemeProvider from "./theme/ThemeProvider";
import { SnackbarProvider } from "notistack";
import TenantProvider from "./providers/ServiceProvider";
import ErrorReporter from "./providers/ErrorReporter";
import { Alert, Snackbar } from "@mui/material";
import useIsOffline from "./hooks/useIsOffline";
import Head from "./components/Print/Head";

function OfflineSnap() {
  const offline = useIsOffline();
  return (
    <Snackbar className="print-hide" open={offline} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
      <Alert variant="filled" severity="error">
        You are offline!
      </Alert>
    </Snackbar>
  );
}

export default function App() {
  return (
    <ErrorReporter>
      <RecoilRoot>
        <AppThemeProvider>
          <OpenIDGuard>
            <CssBaseline />
            <React.StrictMode>
              <TenantProvider>
                <SnackbarProvider maxSnack={4}>
                  <OfflineSnap />
                  <Head />
                  <Main />
                </SnackbarProvider>
              </TenantProvider>
            </React.StrictMode>
          </OpenIDGuard>
        </AppThemeProvider>
      </RecoilRoot>
    </ErrorReporter>
  );
}
