import TableHeader, { Status } from "../../components/Table/TableHeader";
import PrefixStatus from "../../components/PrefixStatus";
import AnchorGap from "../../components/AnchorGap";
import { BGPPrefix, useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import useReportError from "../../hooks/useReportError";
import useSection from "../../hooks/useService";
import { apiClient } from "../../state/atoms";
import { plural } from "../../utils/plural";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { GridColDef } from "@mui/x-data-grid-pro";
import BlindspotTable from "../../components/BlindspotTable";
import { Box, Skeleton } from "@mui/material";

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const countAndParseStatuses = (prefixes: BGPPrefix[], t: TFunction<"index", "receivedipprefixes">): Status[] => {
  const acceptedCount = prefixes.filter(prefix => prefix.status === "accepted").length;
  const deniedCount = prefixes.filter(prefix => prefix.status === "denied").length;
  const underReviewCount = prefixes.filter(prefix => prefix.status === "review").length;

  const accepted = plural(
    acceptedCount,
    n => `${n} ${t("prefix_accepted", { count: acceptedCount })}`,
    n => `${n} ${t("prefix_accepted", { count: acceptedCount })}`
  );
  const denied = plural(
    deniedCount,
    n => `${n} ${t("prefix_denied", { count: acceptedCount })}`,
    n => `${n} ${t("prefix_denied", { count: acceptedCount })}`
  );
  const underReview = plural(
    underReviewCount,
    n => `${n} ${t("prefix_under_review", { count: acceptedCount })}`,
    n => `${n} ${t("prefix_under_review", { count: acceptedCount })}`
  );

  return [
    { name: accepted, state: "ok", count: acceptedCount },
    { name: denied, state: "err", count: deniedCount },
    { name: underReview, state: "warn", count: underReviewCount },
  ];
};

function ReceivedIPPrefixes() {
  const section = useSection();
  const api = useRecoilValue(apiClient);
  const { t } = useTranslation("index", { keyPrefix: "receivedipprefixes" });
  const [service, serviceError, serviceLoading] = useApi([api.get_service(), section]);
  const [connections, error] = useApi([api.get_bgp_prefixes(), section]);

  useReportError(error, "get_bgp_prefixes");
  useReportError(serviceError, "get_service");

  return (
    <>
      <AnchorGap idTarget="ip_prefixes" height={20} />
      <TableHeader
        tittle={t("title")}
        description={
          (serviceLoading && <Skeleton variant="text" sx={{ fontSize: "0.875rem", maxWidth: "40%" }} />) ||
          (service && t("description") + service?.sp_max_accepted_prefixes + ".")
        }
        // buttonUrl={"/bgp-prefixes-log"}
        // buttonTittle={"BGP Received prefixes log"}
        statuses={(connections && countAndParseStatuses(connections, t)) || []}
      />
      <PrefixTable prefixes={connections} error={error !== undefined} />
    </>
  );
}

interface PrefixTableProps {
  prefixes: BGPPrefix[] | undefined;
  error: boolean;
}

function PrefixTable({ prefixes, error }: PrefixTableProps) {
  const { t } = useTranslation("index", { keyPrefix: "receivedipprefixes" });
  const columns: GridColDef[] = [
    {
      headerName: t("ip_prefix"),
      field: "prefix",
      flex: 1,
    },
    {
      headerName: t("net_name"),
      field: "netname",
      flex: 1,
    },
    {
      headerName: t("source_as"),
      field: "as",
      flex: 1,
      renderCell: ({ row }: { row: BGPPrefix }) => (
        <>
          {"AS" + row.as.number}
          <br />
          {row.as.name}
          <br />
        </>
      ),
    },

    {
      headerName: t("prefix_status"),
      field: "status",
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: "accepted", label: "Accepted" },
        { value: "denied", label: "Denied" },
        { value: "review", label: "Review" },
      ],
      renderCell: ({ row }: { row: BGPPrefix }) => {
        const res = capitalizeFirstLetter(row.status);
        return <PrefixStatus status={res} statusName={res} />;
      },
    },
    {
      headerName: t("connection_ids"),
      field: "connections",
      flex: 1.5,
      renderCell: ({ row }: { row: BGPPrefix }) => (
        <Box display="flex" flexDirection="column" width="100%">
          {row.connections.map((x: { name: string; ip: string }, i: number) => (
            <Box
              title={`${x.name} (${x.ip})`}
              key={i}
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >{`${x.name} (${x.ip})`}</Box>
          ))}
        </Box>
      ),
    },
  ];

  return (
    <BlindspotTable
      error={error ? t("error") : undefined}
      rowHeight={80}
      rowsPerPage={5}
      rows={prefixes}
      columns={columns}
      getRowId={prefix => prefix.prefix}
      noDataText={t("no_prefixes")}
    />
  );
}

export default memo(ReceivedIPPrefixes);
