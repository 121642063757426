import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { useTheme } from "@mui/material";
import { alpha } from "@mui/material";
import TopCard from "./TopCard";
import { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";

export default function AttackOkCard() {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "cards.peace.network_status" });
  const loading = false;

  return (
    <TopCard
      isLoading={loading}
      status="peace"
      overline={t("title")}
      h5={t("h5")}
      icon={<ShieldOutlinedIcon color="primary" fontSize="large" />}
    >
      <CustomCardAction caption={{ text: t("info"), color: alpha(theme.palette.text.secondary, 0.3) }} />
    </TopCard>
  );
}
