import React from "react";
import { useTheme } from "@mui/material";

interface LogoProps {
  height?: string;
  width?: string;
}

export default function Logo({ height, width }: LogoProps) {
  const theme = useTheme();
  return theme.palette.mode === "dark" ? (
    <SvgLogoDark height={height} width={width} />
  ) : (
    <SvgLogoLight height={height} width={width} />
  );
}

function SvgLogoDark({ height, width }: LogoProps) {
  return (
    <svg
      width={width || "220"}
      height={height || "77"}
      viewBox="0 0 186 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.2836 32.474V9.31548H50.0176C52.4217 9.31548 54.2413 9.8779 55.4764 11.0027C56.7115 12.1276 57.3291 13.5391 57.3291 15.2374C57.3291 16.7152 56.9321 17.8951 56.1381 18.7774C55.3441 19.6375 54.3847 20.222 53.2598 20.5308C54.142 20.7073 54.925 21.0601 55.6087 21.5895C56.3145 22.1188 56.8659 22.7805 57.2629 23.5745C57.6599 24.3685 57.8584 25.2287 57.8584 26.155C57.8584 27.346 57.5607 28.4267 56.9652 29.3972C56.3917 30.3456 55.5426 31.0955 54.4177 31.6469C53.2929 32.1983 51.9144 32.474 50.2823 32.474H41.2836ZM44.7904 19.2405H49.5875C50.9329 19.2405 51.9585 18.9318 52.6643 18.3142C53.3701 17.6746 53.723 16.8034 53.723 15.7006C53.723 14.6199 53.3701 13.7597 52.6643 13.1201C51.9806 12.4805 50.9329 12.1607 49.5214 12.1607H44.7904V19.2405ZM44.7904 29.5957H49.8191C51.2307 29.5957 52.3224 29.2649 53.0944 28.6032C53.8884 27.9415 54.2854 27.0152 54.2854 25.8242C54.2854 24.6332 53.8774 23.6958 53.0613 23.0121C52.2453 22.3063 51.1425 21.9534 49.753 21.9534H44.7904V29.5957Z"
        fill="white"
      />
      <path d="M61.57 32.474V8.65381H65.0768V32.474H61.57Z" fill="white" />
      <path
        d="M71.5028 12.9547C70.8411 12.9547 70.2897 12.7562 69.8486 12.3592C69.4296 11.9401 69.22 11.4218 69.22 10.8042C69.22 10.1867 69.4296 9.6794 69.8486 9.2824C70.2897 8.86334 70.8411 8.65381 71.5028 8.65381C72.1645 8.65381 72.7048 8.86334 73.1239 9.2824C73.565 9.6794 73.7856 10.1867 73.7856 10.8042C73.7856 11.4218 73.565 11.9401 73.1239 12.3592C72.7048 12.7562 72.1645 12.9547 71.5028 12.9547ZM69.7494 32.474V16.0645H73.2562V32.474H69.7494Z"
        fill="white"
      />
      <path
        d="M77.8856 32.474V16.0645H80.9955L81.2601 18.9428C81.7674 17.9282 82.5063 17.1342 83.4767 16.5608C84.4692 15.9653 85.6051 15.6675 86.8843 15.6675C88.8694 15.6675 90.4243 16.2851 91.5491 17.5202C92.696 18.7553 93.2695 20.597 93.2695 23.0452V32.474H89.7957V23.4091C89.7957 20.2331 88.4944 18.645 85.8918 18.645C84.5905 18.645 83.5098 19.1082 82.6496 20.0345C81.8115 20.9609 81.3925 22.2842 81.3925 24.0046V32.474H77.8856Z"
        fill="white"
      />
      <path
        d="M104.877 32.871C103.289 32.871 101.888 32.496 100.675 31.7461C99.4619 30.9962 98.5135 29.9706 97.8298 28.6694C97.1461 27.3681 96.8042 25.8903 96.8042 24.2362C96.8042 22.582 97.1461 21.1153 97.8298 19.836C98.5135 18.5348 99.4619 17.5202 100.675 16.7924C101.91 16.0425 103.322 15.6675 104.91 15.6675C106.211 15.6675 107.347 15.9212 108.317 16.4284C109.31 16.9357 110.082 17.6525 110.633 18.5789V8.65381H114.14V32.474H110.997L110.633 29.9265C110.104 30.6985 109.376 31.3822 108.45 31.9777C107.523 32.5732 106.332 32.871 104.877 32.871ZM105.505 29.8273C107.005 29.8273 108.229 29.309 109.177 28.2724C110.148 27.2357 110.633 25.9014 110.633 24.2692C110.633 22.6151 110.148 21.2807 109.177 20.2661C108.229 19.2295 107.005 18.7112 105.505 18.7112C104.005 18.7112 102.77 19.2295 101.8 20.2661C100.829 21.2807 100.344 22.6151 100.344 24.2692C100.344 25.35 100.565 26.3094 101.006 27.1475C101.447 27.9856 102.053 28.6473 102.825 29.1325C103.619 29.5957 104.513 29.8273 105.505 29.8273Z"
        fill="white"
      />
      <path
        d="M125.112 32.871C123.039 32.871 121.33 32.3637 119.984 31.3491C118.639 30.3346 117.867 28.9892 117.668 27.3129H121.208C121.385 28.0628 121.804 28.7135 122.465 29.2649C123.127 29.7942 123.998 30.0589 125.079 30.0589C126.138 30.0589 126.91 29.8383 127.395 29.3972C127.88 28.9561 128.123 28.4488 128.123 27.8754C128.123 27.0372 127.781 26.4748 127.097 26.1881C126.435 25.8793 125.509 25.6036 124.318 25.361C123.392 25.1625 122.465 24.8978 121.539 24.567C120.635 24.2362 119.874 23.773 119.256 23.1775C118.661 22.5599 118.363 21.7328 118.363 20.6962C118.363 19.2626 118.914 18.0716 120.017 17.1232C121.12 16.1527 122.664 15.6675 124.649 15.6675C126.48 15.6675 127.957 16.1086 129.082 16.9909C130.229 17.8731 130.902 19.1192 131.1 20.7293H127.726C127.615 20.0235 127.285 19.4721 126.733 19.0751C126.204 18.6781 125.487 18.4796 124.583 18.4796C123.701 18.4796 123.017 18.6671 122.532 19.042C122.046 19.3949 121.804 19.8581 121.804 20.4316C121.804 21.005 122.135 21.4571 122.796 21.788C123.48 22.1188 124.373 22.4166 125.476 22.6812C126.579 22.9238 127.593 23.2106 128.52 23.5414C129.468 23.8502 130.229 24.3134 130.802 24.9309C131.376 25.5485 131.663 26.4528 131.663 27.6438C131.685 29.1436 131.1 30.3897 129.909 31.3822C128.74 32.3747 127.141 32.871 125.112 32.871Z"
        fill="white"
      />
      <path
        d="M135.491 39.7523V16.0645H138.634L138.998 18.612C139.527 17.84 140.255 17.1563 141.181 16.5608C142.108 15.9653 143.299 15.6675 144.754 15.6675C146.342 15.6675 147.743 16.0425 148.956 16.7924C150.169 17.5423 151.118 18.5678 151.801 19.8691C152.507 21.1704 152.86 22.6482 152.86 24.3023C152.86 25.9565 152.507 27.4342 151.801 28.7355C151.118 30.0148 150.169 31.0293 148.956 31.7792C147.743 32.507 146.331 32.871 144.721 32.871C143.442 32.871 142.306 32.6173 141.314 32.11C140.343 31.6028 139.571 30.886 138.998 29.9596V39.7523H135.491ZM144.126 29.8273C145.626 29.8273 146.861 29.32 147.831 28.3054C148.802 27.2688 149.287 25.9234 149.287 24.2692C149.287 23.1885 149.066 22.2291 148.625 21.391C148.184 20.5529 147.578 19.9022 146.806 19.439C146.034 18.9538 145.14 18.7112 144.126 18.7112C142.626 18.7112 141.391 19.2295 140.421 20.2661C139.472 21.3028 138.998 22.6371 138.998 24.2692C138.998 25.9234 139.472 27.2688 140.421 28.3054C141.391 29.32 142.626 29.8273 144.126 29.8273Z"
        fill="white"
      />
      <path
        d="M164.328 32.871C162.762 32.871 161.351 32.5181 160.094 31.8123C158.858 31.0845 157.877 30.0809 157.149 28.8017C156.421 27.5004 156.057 25.9896 156.057 24.2692C156.057 22.5489 156.421 21.0491 157.149 19.7699C157.899 18.4686 158.903 17.4651 160.16 16.7593C161.417 16.0314 162.817 15.6675 164.361 15.6675C165.927 15.6675 167.328 16.0314 168.563 16.7593C169.82 17.4651 170.813 18.4686 171.54 19.7699C172.29 21.0491 172.665 22.5489 172.665 24.2692C172.665 25.9896 172.29 27.5004 171.54 28.8017C170.813 30.0809 169.82 31.0845 168.563 31.8123C167.306 32.5181 165.894 32.871 164.328 32.871ZM164.328 29.8604C165.166 29.8604 165.938 29.6508 166.644 29.2318C167.372 28.8127 167.956 28.1952 168.398 27.3791C168.839 26.541 169.059 25.5044 169.059 24.2692C169.059 23.0341 168.839 22.0085 168.398 21.1925C167.979 20.3544 167.405 19.7258 166.677 19.3067C165.971 18.8877 165.199 18.6781 164.361 18.6781C163.523 18.6781 162.74 18.8877 162.012 19.3067C161.307 19.7258 160.733 20.3544 160.292 21.1925C159.851 22.0085 159.63 23.0341 159.63 24.2692C159.63 25.5044 159.851 26.541 160.292 27.3791C160.733 28.1952 161.307 28.8127 162.012 29.2318C162.718 29.6508 163.49 29.8604 164.328 29.8604Z"
        fill="white"
      />
      <path
        d="M182.693 32.474C181.083 32.474 179.804 32.088 178.855 31.316C177.907 30.522 177.433 29.1215 177.433 27.1144V19.009H174.621V16.0645H177.433L177.863 11.896H180.94V16.0645H185.571V19.009H180.94V27.1144C180.94 28.0187 181.127 28.6473 181.502 29.0002C181.899 29.331 182.572 29.4964 183.52 29.4964H185.406V32.474H182.693Z"
        fill="white"
      />
      <g opacity="0.8">
        <path
          d="M22.5726 10.1319L5.77701 0.502377C3.28752 -0.924638 0.178467 0.858205 0.178467 3.71223V22.9714C0.178467 25.8254 3.28752 27.6082 5.77701 26.1812L22.5726 16.5517C25.0621 15.1246 25.0621 11.559 22.5726 10.1319Z"
          fill="#00BFA5"
        />
      </g>
      <g opacity="0.8">
        <path
          d="M22.5726 26.2399L5.77701 16.6103C3.28752 15.1833 0.178467 16.9661 0.178467 19.8201V39.0793C0.178467 41.9333 3.28752 43.7162 5.77701 42.2891L22.5726 32.6596C25.0621 31.2326 25.0621 27.6669 22.5726 26.2399Z"
          fill="#00B0FF"
        />
      </g>
    </svg>
  );
}

function SvgLogoLight({ height, width }: LogoProps) {
  return (
    <svg
      width={width || "220"}
      height={height || "77"}
      viewBox="0 0 186 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.2836 32.4737V9.31523H50.0176C52.4217 9.31523 54.2413 9.87765 55.4764 11.0025C56.7115 12.1273 57.3291 13.5389 57.3291 15.2372C57.3291 16.7149 56.9321 17.8949 56.1381 18.7771C55.3441 19.6373 54.3847 20.2218 53.2598 20.5306C54.142 20.707 54.925 21.0599 55.6087 21.5892C56.3145 22.1186 56.8659 22.7802 57.2629 23.5742C57.6599 24.3683 57.8584 25.2284 57.8584 26.1548C57.8584 27.3458 57.5607 28.4265 56.9652 29.397C56.3917 30.3453 55.5426 31.0952 54.4177 31.6466C53.2929 32.198 51.9144 32.4737 50.2823 32.4737H41.2836ZM44.7904 19.2403H49.5875C50.9329 19.2403 51.9585 18.9315 52.6643 18.314C53.3701 17.6743 53.723 16.8031 53.723 15.7004C53.723 14.6196 53.3701 13.7595 52.6643 13.1198C51.9806 12.4802 50.9329 12.1604 49.5214 12.1604H44.7904V19.2403ZM44.7904 29.5955H49.8191C51.2307 29.5955 52.3224 29.2646 53.0944 28.6029C53.8884 27.9413 54.2854 27.0149 54.2854 25.8239C54.2854 24.6329 53.8774 23.6956 53.0613 23.0118C52.2453 22.306 51.1425 21.9532 49.753 21.9532H44.7904V29.5955Z"
        fill="#151515"
      />
      <path d="M61.57 32.4737V8.65356H65.0768V32.4737H61.57Z" fill="#151515" />
      <path
        d="M71.5028 12.9544C70.8411 12.9544 70.2897 12.7559 69.8486 12.3589C69.4296 11.9399 69.22 11.4216 69.22 10.804C69.22 10.1864 69.4296 9.67915 69.8486 9.28215C70.2897 8.86309 70.8411 8.65356 71.5028 8.65356C72.1645 8.65356 72.7048 8.86309 73.1239 9.28215C73.565 9.67915 73.7856 10.1864 73.7856 10.804C73.7856 11.4216 73.565 11.9399 73.1239 12.3589C72.7048 12.7559 72.1645 12.9544 71.5028 12.9544ZM69.7494 32.4737V16.0643H73.2562V32.4737H69.7494Z"
        fill="#151515"
      />
      <path
        d="M77.8856 32.4737V16.0643H80.9955L81.2601 18.9425C81.7674 17.928 82.5063 17.134 83.4767 16.5605C84.4692 15.965 85.6051 15.6673 86.8843 15.6673C88.8694 15.6673 90.4243 16.2848 91.5491 17.52C92.696 18.7551 93.2695 20.5967 93.2695 23.0449V32.4737H89.7957V23.4088C89.7957 20.2328 88.4944 18.6448 85.8918 18.6448C84.5905 18.6448 83.5098 19.108 82.6496 20.0343C81.8115 20.9606 81.3925 22.284 81.3925 24.0043V32.4737H77.8856Z"
        fill="#151515"
      />
      <path
        d="M104.877 32.8707C103.289 32.8707 101.888 32.4958 100.675 31.7459C99.4619 30.996 98.5135 29.9704 97.8298 28.6691C97.1461 27.3678 96.8042 25.8901 96.8042 24.2359C96.8042 22.5817 97.1461 21.115 97.8298 19.8358C98.5135 18.5345 99.4619 17.52 100.675 16.7921C101.91 16.0422 103.322 15.6673 104.91 15.6673C106.211 15.6673 107.347 15.9209 108.317 16.4282C109.31 16.9355 110.082 17.6523 110.633 18.5786V8.65356H114.14V32.4737H110.997L110.633 29.9263C110.104 30.6982 109.376 31.382 108.45 31.9775C107.523 32.573 106.332 32.8707 104.877 32.8707ZM105.505 29.827C107.005 29.827 108.229 29.3087 109.177 28.2721C110.148 27.2355 110.633 25.9011 110.633 24.269C110.633 22.6148 110.148 21.2805 109.177 20.2659C108.229 19.2293 107.005 18.711 105.505 18.711C104.005 18.711 102.77 19.2293 101.8 20.2659C100.829 21.2805 100.344 22.6148 100.344 24.269C100.344 25.3497 100.565 26.3092 101.006 27.1473C101.447 27.9854 102.053 28.6471 102.825 29.1323C103.619 29.5955 104.513 29.827 105.505 29.827Z"
        fill="#151515"
      />
      <path
        d="M125.112 32.8707C123.039 32.8707 121.33 32.3634 119.984 31.3489C118.639 30.3343 117.867 28.9889 117.668 27.3127H121.208C121.385 28.0626 121.804 28.7132 122.465 29.2646C123.127 29.794 123.998 30.0586 125.079 30.0586C126.138 30.0586 126.91 29.8381 127.395 29.397C127.88 28.9558 128.123 28.4486 128.123 27.8751C128.123 27.037 127.781 26.4746 127.097 26.1878C126.435 25.8791 125.509 25.6034 124.318 25.3608C123.392 25.1623 122.465 24.8976 121.539 24.5668C120.635 24.2359 119.874 23.7727 119.256 23.1772C118.661 22.5597 118.363 21.7326 118.363 20.696C118.363 19.2624 118.914 18.0713 120.017 17.123C121.12 16.1525 122.664 15.6673 124.649 15.6673C126.48 15.6673 127.957 16.1084 129.082 16.9906C130.229 17.8728 130.902 19.119 131.1 20.7291H127.726C127.615 20.0233 127.285 19.4719 126.733 19.0749C126.204 18.6779 125.487 18.4794 124.583 18.4794C123.701 18.4794 123.017 18.6669 122.532 19.0418C122.046 19.3947 121.804 19.8579 121.804 20.4313C121.804 21.0048 122.135 21.4569 122.796 21.7877C123.48 22.1186 124.373 22.4163 125.476 22.681C126.579 22.9236 127.593 23.2103 128.52 23.5412C129.468 23.8499 130.229 24.3131 130.802 24.9307C131.376 25.5482 131.663 26.4525 131.663 27.6435C131.685 29.1433 131.1 30.3895 129.909 31.382C128.74 32.3745 127.141 32.8707 125.112 32.8707Z"
        fill="#151515"
      />
      <path
        d="M135.491 39.7521V16.0643H138.634L138.998 18.6117C139.527 17.8398 140.255 17.156 141.181 16.5605C142.108 15.965 143.299 15.6673 144.754 15.6673C146.342 15.6673 147.743 16.0422 148.956 16.7921C150.169 17.542 151.118 18.5676 151.801 19.8689C152.507 21.1702 152.86 22.6479 152.86 24.3021C152.86 25.9563 152.507 27.434 151.801 28.7353C151.118 30.0145 150.169 31.0291 148.956 31.779C147.743 32.5068 146.331 32.8707 144.721 32.8707C143.442 32.8707 142.306 32.6171 141.314 32.1098C140.343 31.6025 139.571 30.8857 138.998 29.9594V39.7521H135.491ZM144.126 29.827C145.626 29.827 146.861 29.3198 147.831 28.3052C148.802 27.2686 149.287 25.9232 149.287 24.269C149.287 23.1883 149.066 22.2289 148.625 21.3907C148.184 20.5526 147.578 19.902 146.806 19.4388C146.034 18.9536 145.14 18.711 144.126 18.711C142.626 18.711 141.391 19.2293 140.421 20.2659C139.472 21.3025 138.998 22.6369 138.998 24.269C138.998 25.9232 139.472 27.2686 140.421 28.3052C141.391 29.3198 142.626 29.827 144.126 29.827Z"
        fill="#151515"
      />
      <path
        d="M164.328 32.8707C162.762 32.8707 161.351 32.5178 160.094 31.8121C158.858 31.0842 157.877 30.0807 157.149 28.8014C156.421 27.5002 156.057 25.9893 156.057 24.269C156.057 22.5487 156.421 21.0489 157.149 19.7696C157.899 18.4684 158.903 17.4648 160.16 16.759C161.417 16.0312 162.817 15.6673 164.361 15.6673C165.927 15.6673 167.328 16.0312 168.563 16.759C169.82 17.4648 170.813 18.4684 171.54 19.7696C172.29 21.0489 172.665 22.5487 172.665 24.269C172.665 25.9893 172.29 27.5002 171.54 28.8014C170.813 30.0807 169.82 31.0842 168.563 31.8121C167.306 32.5178 165.894 32.8707 164.328 32.8707ZM164.328 29.8601C165.166 29.8601 165.938 29.6506 166.644 29.2315C167.372 28.8125 167.956 28.1949 168.398 27.3789C168.839 26.5407 169.059 25.5041 169.059 24.269C169.059 23.0339 168.839 22.0083 168.398 21.1922C167.979 20.3541 167.405 19.7255 166.677 19.3065C165.971 18.8874 165.199 18.6779 164.361 18.6779C163.523 18.6779 162.74 18.8874 162.012 19.3065C161.307 19.7255 160.733 20.3541 160.292 21.1922C159.851 22.0083 159.63 23.0339 159.63 24.269C159.63 25.5041 159.851 26.5407 160.292 27.3789C160.733 28.1949 161.307 28.8125 162.012 29.2315C162.718 29.6506 163.49 29.8601 164.328 29.8601Z"
        fill="#151515"
      />
      <path
        d="M182.693 32.4737C181.083 32.4737 179.804 32.0877 178.855 31.3158C177.907 30.5218 177.433 29.1213 177.433 27.1142V19.0087H174.621V16.0643H177.433L177.863 11.8958H180.94V16.0643H185.571V19.0087H180.94V27.1142C180.94 28.0185 181.127 28.6471 181.502 28.9999C181.899 29.3308 182.572 29.4962 183.52 29.4962H185.406V32.4737H182.693Z"
        fill="#151515"
      />
      <g opacity="0.8">
        <path
          d="M22.5726 10.1319L5.77701 0.502377C3.28752 -0.924638 0.178467 0.858205 0.178467 3.71223V22.9714C0.178467 25.8254 3.28752 27.6082 5.77701 26.1812L22.5726 16.5517C25.0621 15.1246 25.0621 11.559 22.5726 10.1319Z"
          fill="#00897B"
        />
      </g>
      <g opacity="0.8">
        <path
          d="M22.5726 26.24L5.77701 16.6104C3.28752 15.1834 0.178467 16.9662 0.178467 19.8203V39.0794C0.178467 41.9334 3.28752 43.7163 5.77701 42.2893L22.5726 32.6597C25.0621 31.2327 25.0621 27.667 22.5726 26.24Z"
          fill="#0277BD"
        />
      </g>
    </svg>
  );
}
