import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { useApi } from "@blindspot/bspot-api-lib";
import { useTranslation } from "react-i18next";

export default function BGP() {
  const api = useRecoilValue(apiClient);
  const { t } = useTranslation("index", { keyPrefix: "cards.peace.BGP_prefixes" });
  const service = useService();
  const [data, err, isLoading] = useApi([api.get_bgp_sessions(), service]);

  const acceptedCount = data?.bgp_received_prefixes.filter(prefix => prefix.prefix_status === "accepted").length;
  const deniedCount = data?.bgp_received_prefixes.filter(prefix => prefix.prefix_status === "denied").length;
  const loading = isLoading;

  return (
    <TopCard
      isLoading={loading}
      status="normal"
      overline={t("title")}
      h5={acceptedCount !== undefined ? `${acceptedCount} ${t("count_propagated", { count: acceptedCount })}` : ""}
      dotStatuses={deniedCount ? [{ state: "warn", name: `${deniedCount} prefix denied` }] : []}
      icon={
        deniedCount ? (
          <WarningAmberOutlinedIcon color="warning" fontSize="large" sx={{ alignSelf: "flex-start" }} />
        ) : (
          <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" sx={{ alignSelf: "flex-start" }} />
        )
      }
    >
      <CustomCardAction linkToIdElement={{ text: t("link"), href: "#ip_prefixes", color: "primary" }} />
    </TopCard>
  );
}
