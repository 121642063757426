import { history } from "../providers/router/Router";

export const navigate = (to: string) => {
  window.history.pushState({}, "", to);
  const navigationEvent = new PopStateEvent("popstate");
  window.dispatchEvent(navigationEvent);
  //@ts-expect-error no instant in library
  window.scrollTo({ top: 0, behavior: "instant" });
};

export const back = () => history.back();
