import { useEffect, useState } from "react";

export default function useIsOffline() {
  const [offline, setOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleStatusChange = () => {
      setOffline(!navigator.onLine);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  return offline;
}
