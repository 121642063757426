import { ReactElement } from "react";
import { useRecoilState } from "recoil";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { IconButton } from "@mui/material";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import { appThemeMode } from "../state/atoms";
import { alpha, useTheme } from "@mui/material";

export default function ModeToggleButton(): ReactElement {
  const [mode, setMode] = useRecoilState(appThemeMode);
  const theme = useTheme();

  const toggleMode = () =>
    setMode((prevState) => (prevState === "light" ? "dark" : "light"));

  return (
    <IconButton onClick={toggleMode} sx={{ width: 40, height: 40, color: theme.palette.action.active }}>
      {mode === "dark"
        ? <LightModeOutlinedIcon sx={{ color:theme.palette.action.active  }} />
        : <NightlightRoundIcon sx={{ color:  alpha(theme.palette.text.secondary, 0.70)}} />}
    </IconButton>
  );
}