import TableHeader, { Status } from "../../components/Table/TableHeader";
import PrefixStatus from "../../components/PrefixStatus";
import { Prefix, useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import { apiClient } from "../../state/atoms";
import useTenant from "../../hooks/useService";
import useReportError from "../../hooks/useReportError";
import { plural } from "../../utils/plural";
import { memo } from "react";
import BlindspotTable from "../../components/BlindspotTable";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid-pro";

const countAndParseStatuses = (prefixes: Prefix[]): Status[] => {
  const acceptedCount = prefixes.filter(prefix => prefix.prefix_status === "accepted").length;
  const deniedCount = prefixes.filter(prefix => prefix.prefix_status === "denied").length;
  const underReviewCount = prefixes.filter(prefix => prefix.prefix_status === "review").length;

  const accepted = plural(
    acceptedCount,
    n => `${n} prefix accepted`,
    n => `${n} prefixes accepted`
  );
  const denied = plural(
    deniedCount,
    n => `${n} prefix denied`,
    n => `${n} prefixes denied`
  );
  const underReview = plural(
    underReviewCount,
    n => `${n} prefix under review`,
    n => `${n} prefixes under review`
  );

  return [
    { name: accepted, state: "ok", count: acceptedCount },
    { name: denied, state: "err", count: deniedCount },
    { name: underReview, state: "warn", count: underReviewCount },
  ];
};

function CustomerIPPrefixes() {
  const tenant = useTenant();
  const api = useRecoilValue(apiClient);
  const [prefixes, error] = useApi([api.get_prefixes(), tenant]);
  const { t } = useTranslation("index", { keyPrefix: "customeripprefixes" });

  useReportError(error, "get_prefixes");

  return (
    <>
      <TableHeader
        tittle={t("title")}
        description={t("description")}
        buttonUrl={"/all-ip-prefixes"}
        buttonTittle={t("buttonTittle")}
        statuses={prefixes && countAndParseStatuses(prefixes)}
      />
      <PrefixTable prefixes={prefixes} error={error !== undefined} />
    </>
  );
}

function PrefixTable({ prefixes, error }: { prefixes: Prefix[] | undefined; error: boolean }) {
  const { t } = useTranslation("index", { keyPrefix: "customeripprefixes" });
  const columns: GridColDef[] = [
    {
      headerName: t("ip_prefix"),
      field: "prefix",
      flex: 1,
    },
    {
      headerName: t("net_name"),
      field: "net_name",
      flex: 1,
    },
    {
      headerName: t("prefix_status"),
      field: "prefix_status",
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: "accepted", label: "Accepted" },
        { value: "denied", label: "Denied" },
        { value: "review", label: "Review" },
      ],
      renderCell: ({ row }) => {
        const res =
          row.prefix_status === "accepted"
            ? t("body.prefix_status_accepted")
            : row.prefix_status === "denied"
            ? t("body.prefix_status_denied")
            : t("body.prefix_status");
        return <PrefixStatus status={res} statusName={res} />;
      },
    },
    {
      headerName: t("max"),
      field: "rpki_max_length",
      type: "number",
      align: "left",
      headerAlign: "left",
      flex: 1,
      valueFormatter: value => "/" + value,
    },
    {
      headerName: t("source_as"),
      field: "asn",
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {"AS" + row.asn}
          <br />
          {row.asn_name}
        </>
      ),
    },
    {
      headerName: t("rpki"),
      field: "rpki_status",
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { label: "RPKI valid", value: "valid" },
        { label: "RPKI override", value: "override" },
        { label: "RPKI invalid", value: "invalid" },
        { label: "RPKI not fount", value: "not_found" },
      ],
      valueFormatter: (value: any) => "RPKI " + value.replaceAll("_", " "),
    },
  ];
  return (
    <BlindspotTable
      sx={{ marginBottom: "2rem" }}
      error={error ? t("error") : undefined}
      rowHeight={65}
      noDataText={"No prefixes found"}
      rowsPerPage={5}
      rows={prefixes}
      columns={columns}
      getRowId={prefix => prefix.prefix}
    />
  );
}

export default memo(CustomerIPPrefixes);
