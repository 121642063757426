import React, { useCallback } from "react";
import { navigate } from "../../utils/navigation";
import { LinkProps } from "@mui/material";
import { Link as MuiLink } from "@mui/material";

export default function Link({ href, children, ...rest }: LinkProps) {
  const preventReload = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      navigate(href || "#");
    },
    [href]
  );
  return (
    <MuiLink href={href} {...rest} onClick={preventReload}>
      {children}
    </MuiLink>
  );
}
