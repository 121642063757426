import { Box, Chip, Typography } from "@mui/material";
import TableHeader, { Status } from "../../components/Table/TableHeader";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AnchorGap from "../../components/AnchorGap";
import { memo } from "react";
import useTenant from "../../hooks/useService";
import { BgpConnection, BGPConnection, useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import useReportError from "../../hooks/useReportError";
import { apiClient } from "../../state/atoms";
import getNearestTimeUnit from "../../utils/getNearestTimeUnit";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { GridColDef } from "@mui/x-data-grid-pro";
import BlindspotTable from "../../components/BlindspotTable";

const countAndParseStatuses = (connections: BGPConnection[], t: TFunction<"index", "connections">): Status[] => {
  const acceptedCount = connections.filter(connection => connection.is_up).length;
  const deniedCount = connections.length - acceptedCount;

  const accepted = `${acceptedCount} ${t("count_alive")}`;
  const denied = `${deniedCount} ${t("count_down", { count: deniedCount })}`;

  return [
    { name: accepted, state: "ok", count: acceptedCount },
    { name: denied, state: "err", count: deniedCount },
  ];
};

function Connections() {
  const tenant = useTenant();
  const { t } = useTranslation("index", { keyPrefix: "connections" });
  const api = useRecoilValue(apiClient);
  const [connections, error] = useApi([api.get_bgp_connections(), tenant]);
  useReportError(error, "get_bgp_connections");

  return (
    <>
      <AnchorGap idTarget="connections" height={30} />
      <Box sx={{ marginTop: "5.8rem" }}>
        <TableHeader
          tittle={t("title")}
          description={t("description")}
          statuses={(connections && countAndParseStatuses(connections, t)) || []}
          /*buttonTittle={"Connection log"}
          buttonUrl={"connection-log"}*/
        />
        <ConnectionTable connections={connections} error={error !== undefined} />
      </Box>
    </>
  );
}

function ConnectionTable({ connections, error }: { connections: BGPConnection[] | undefined; error: boolean }) {
  const { t } = useTranslation("index", { keyPrefix: "connections" });

  const columns: GridColDef[] = [
    {
      headerName: t("body.connection_name"),
      field: "connection_name",
      flex: 1,
      renderCell: ({ row }: { row: BGPConnection }) => (
        <Box>
          {row.connection_name}
          <br />
          {row.bgp_session_name}
        </Box>
      ),
    },

    {
      headerName: t("body.interconnection_status"),
      field: "bgpsession_status",
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter: (value, row) => row.last_down_timestamp * 1000,
      renderCell: ({ row }: { row: BGPConnection }) => (
        <Box display={"flex"}>
          {row.is_up && (
            <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )}
          {!row.is_up && <ErrorIcon color="error" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />}
          <Box pl={1}>
            <Typography variant="subtitle2">
              {row.is_up ? t("up") : t("link_down")}
              <br />
            </Typography>
            Since {getNearestTimeUnit(Date.now() - row.last_down_timestamp * 1000, i18n.language)}
            <br />
            {row.is_up ? t("bfd_enabled") : t("bfd_enabled")}
          </Box>
        </Box>
      ),
    },
    {
      headerName: t("body.connection_details"),
      field: "blindspot_ip",
      flex: 1,
      cellClassName: "wrap",
      renderCell: ({ row }: { row: BGPConnection }) => (
        <>
          {t("blindspot_ip") + row.blindspot_ip}
          <br />
          {t("customer_ip") + row.customer_ip}
          <br />
        </>
      ),
    },
    {
      headerName: t("body.connection_type"),
      field: "connection_type",
      flex: 1,
      valueGetter: (value, row) => row.connection_type,
      renderCell: ({ row }: { row: BGPConnection }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip
            sx={{
              height: "26px",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            }}
            color="primary"
            label={row.connection_type.toUpperCase()}
            variant="filled"
          />
          <div style={{ marginLeft: "5px" }}>{row.connection_location}</div>
        </Box>
      ),
    },
    {
      headerName: t("body.bgp_session_status"),
      field: "bgp_session_status",
      flex: 1,
      sortable: false,
      filterable: false,
      cellClassName: "wrap",
      valueGetter: (value, row) => row.last_down_timestamp * 1000,
      renderCell: ({ row }: { row: BGPConnection }) => (
        <Box display={"flex"}>
          {row.is_up && (
            <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )}
          {!row.is_up && <ErrorIcon color="error" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />}
          <Box pl={1}>
            <Typography variant="subtitle2">
              {t("status", { context: row.is_up ? "established" : "not connected" })}
            </Typography>
            Since {getNearestTimeUnit(Date.now() - row.last_down_timestamp * 1000, i18n.language)}
            <br />
            {t("prefixes_received", { count: row.prefix_count })}
            <br />
          </Box>
        </Box>
      ),
    },
  ];
  return (
    <BlindspotTable
      error={error ? t("error") : undefined}
      rowHeight={92}
      rowsPerPage={3}
      rows={connections}
      columns={columns}
      getRowId={connections => connections.blindspot_ip}
      noDataText={t("no_connections")}
    />
  );
}

export default memo(Connections);
