import { Box, SxProps, useTheme } from "@mui/material";

type DotProps = {
  sx?: SxProps;
} & ({ status: "ok" | "err" | "warn" | "info" } | { color: string });

export default function Dot({ sx, ...rest }: DotProps) {
  const theme = useTheme();

  let color;
  if ("status" in rest) {
    color = {
      ok: theme.palette.success.main,
      err: theme.palette.error.main,
      warn: theme.palette.warning.main,
      info: theme.palette.info.main,
    }[rest.status];
  } else {
    color = rest.color;
  }

  return (
    <Box
      sx={{
        width: "8px",
        height: "8px",
        minWidth: "8px",
        minHeight: "8px",
        borderRadius: "5px",

        backgroundColor: color,
        ...sx,
      }}
    ></Box>
  );
}
