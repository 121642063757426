import { Grid } from "@mui/material";
import TrafficGraph from "../../components/TrafficGraph";
import AttackOkCard from "../../components/AttackOkCard";
import AttackErrCard from "../../components/AttackErrCard";
import AttackHistoryOkCard from "../../components/AttackHistoryOkCard";
import AttackHistoryErrCard from "../../components/AttackHistoryErrCard";
import ConnectionsErrCard from "../../components/ConnectionsErrCard";
import ConnectionsCard from "../../components/ConnectionsCard";
import BGP from "../../components/BGP";
import BGPErr from "../../components/BGPErr";
import AttacklogErr from "./AttackLogErr";
import useService from "../../hooks/useService";
import { useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { apiClient, underAttack } from "../../state/atoms";
import { useEffect } from "react";

export default function Graphs() {
  const service = useService();
  const api = useRecoilValue(apiClient);

  const [a_paginaged, error] = useApi([
    api.get_attacks_paginated(),
    {
      service,
      limit: 32,
      offset: 0,
      attack_state: ["Ongoing"],
    },
  ]);

  const attacks = a_paginaged?.data;

  const setUnderAttack = useSetRecoilState(underAttack);

  useEffect(() => {
    attacks && setUnderAttack(attacks.length > 0);
  }, [(attacks?.length || 0) > 0]);

  return (
    <>
      <Grid container spacing="1.5rem" columns={4} sx={{ alignItems: "stretch" }}>
        <Grid item xs={1}>
          {attacks && attacks.length > 0 ? <AttackErrCard attacks={attacks} /> : <AttackOkCard />}
        </Grid>
        <Grid item xs={1}>
          {attacks && attacks.length > 0 ? <AttackHistoryErrCard attacks={attacks} /> : <AttackHistoryOkCard />}
        </Grid>
        <Grid item xs={1}>
          {attacks && attacks.length > 0 ? <ConnectionsErrCard attacks={attacks} /> : <ConnectionsCard />}
        </Grid>
        <Grid item xs={1}>
          {attacks && attacks.length > 0 ? <BGPErr /> : <BGP />}
        </Grid>
      </Grid>
      <Grid item xs={2} sx={{ alignItems: "stretch", marginY: "1.5rem" }}>
        <TrafficGraph />
      </Grid>

      {/* <Grid container spacing="1.5rem" columns={3} sx={{ alignItems: "stretch", marginTop: "0.1rem" }}>
        <Grid item xs={2}>
          <TrafficGraph />
        </Grid>
        <Grid item xs={1}>
          <SFlowGraph />
        </Grid>
      </Grid> */}
      {attacks && attacks.length > 0 ? <AttacklogErr error={error !== undefined} attacks={attacks} /> : <></>}
    </>
  );
}
