import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  Link,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyIcon from "@mui/icons-material/Key";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AUTH_URI, AUTH_REALM, CLIENT_ID, REDIRECT_URI } from "../consts";

export default function BlindspotAvatar({
  given_name,
  family_name,
  email,
}: {
  given_name: string;
  family_name: string;
  email: string;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const LOGOUT = `${AUTH_URI}/realms/${AUTH_REALM}/protocol/openid-connect/logout?client_id=${CLIENT_ID}&post_logout_redirect_uri=${encodeURIComponent(
    REDIRECT_URI
  )}`;
  const AUTH_ACCOUNT = `${AUTH_URI}/realms/${AUTH_REALM}/account?referrer=${CLIENT_ID}&referrer_uri=${window.location}`;

  return (
    <>
      <Box>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <Avatar sx={{ bgcolor: stringToColor(given_name + family_name) }}>{given_name.charAt(0)}</Avatar>
        </IconButton>
        <Menu
          id="basic-menu"
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            marginTop: "1rem",
            "& .MuiPaper-root": {
              boxShadow:
                "0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px -5px rgba(0, 0, 0, 0.20);",
            },
          }}
        >
          <Box sx={{ minWidth: "16.25rem", boxShadow: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>
                <IconButton sx={{ marginX: "1rem" }} onClick={() => setAnchorEl(null)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Avatar
                sx={{ marginBottom: "0.5rem", width: 56, height: 56, bgcolor: stringToColor(given_name + family_name) }}
              >
                {given_name.charAt(0)}
              </Avatar>
              <Typography variant="body2">{given_name + " " + family_name}</Typography>
              <Typography variant="caption">{email}</Typography>
            </Box>
            <Divider sx={{ marginY: "0.5rem" }} />
            {/* <MenuItem component={Link} href={"/#"} sx={{ minHeight: "2.25rem" }}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem> */}
            <MenuItem component={Link} href={AUTH_ACCOUNT} sx={{ minHeight: "2.25rem" }}>
              <ListItemIcon>
                <KeyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Blindspot Account</ListItemText>
            </MenuItem>
            {/* <MenuItem component={Link} href={"/#"} sx={{ minHeight: "2.25rem" }}>
              <ListItemIcon>
                <CreditCardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Billing</ListItemText>
            </MenuItem> */}
            <Divider sx={{ marginY: "0.5rem" }} />
            <MenuItem component={Link} href={LOGOUT} sx={{ minHeight: "2.25rem" }}>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LogoutIcon sx={{ marginY: "6px", marginLeft: "5px", marginRight: "8px" }} />
                  <Typography variant="button">Log out</Typography>
                </Box>
              </Box>
            </MenuItem>
          </Box>
        </Menu>
      </Box>
    </>
  );
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
