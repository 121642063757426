import { ApiError } from "@blindspot/bspot-api-lib/lib/helpers";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { login } from "../providers/OpenIDGuard";

export const errors = {
  get_whoami: "get_whoami",
  get_health: "get_health",
  get_service: "get_service",
  get_prefixes: "get_prefixes",
  get_prefixes_log: "get_prefixes_log",
  get_traffic: "get_traffic",
  get_traffic_protocols: "get_traffic_protocols",
  get_traffic_countries: "get_traffic_countries",
  get_traffic_destinations: "get_traffic_destinations",
  get_bgp_connections: "get_bgp_connections",
  get_bgp_prefixes: "get_bgp_prefixes",
  get_tenant: "get_tenant",
  get_attack_log: "get_attack_log",
  get_bgp_sessions: "get_bgp_sessions",
  get_bgp_sessions_logs: "get_bgp_sessions_logs",
  get_attack: "get_attack",
  get_latest_attacks: "get_latest_attacks",
  get_attack_count: "get_attack_count",
  get_create_report: "get_create_report",
};

type Msg = keyof typeof errors;

export default function useReportError(error: ApiError | undefined, msg: Msg): void;
export default function useReportError(error: (ApiError | undefined)[], msg: Msg[]): void;
export default function useReportError(error: ApiError | (ApiError | undefined)[] | undefined, msg: Msg | Msg[]) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("errors", { useSuspense: false });

  useEffect(
    () => {
      if (Array.isArray(error)) {
        error.forEach((err, i) => {
          if (err === undefined) return;
          if (err?.status_code === 401) {
            login();
            return;
          }
          if (err?.status_code === 204) {
            return;
          }
          enqueueSnackbar(t(msg[i]), {
            variant: "error",
            className: "print-hide",
            preventDuplicate: true,
          });
        });
      } else if (error?.status_code === 401) {
        login();
      } else if (error?.status_code === 204) {
        return;
      } else {
        error &&
          enqueueSnackbar(t(msg), {
            variant: "error",
            className: "print-hide",
            preventDuplicate: true,
          });
      }
    },
    Array.isArray(error) ? [...error] : [error]
  );
}
