export default function getNearestTimeUnit(
  timeInMS: number,
  locale: Intl.UnicodeBCP47LocaleIdentifier,
  minimalTime?: "day"
): string {
  const timeInSeconds = Math.round(timeInMS / 1000);
  const timeInDays = timeInMS / (1000 * 60 * 60 * 24); // Convert to days
  const roundedTime = Math.round(timeInDays); // Round to the nearest whole number

  if (roundedTime >= 365) {
    const formatter = new Intl.NumberFormat(locale, {
      style: "unit",
      unit: "year",
      unitDisplay: "long",
    });
    const years = Math.floor(roundedTime / 365);
    return formatter.format(years);
  } else if (roundedTime >= 30) {
    const formatter = new Intl.NumberFormat(locale, {
      style: "unit",
      unit: "month",
      unitDisplay: "long",
    });
    const months = Math.floor(roundedTime / 30);
    return formatter.format(months);
  } else if (timeInSeconds >= 24 * 60 * 60 || minimalTime === "day") {
    const formatter = new Intl.NumberFormat(locale, {
      style: "unit",
      unit: "day",
      unitDisplay: "long",
    });
    return formatter.format(roundedTime);
  } else if (timeInSeconds >= 60 * 60) {
    const formatter = new Intl.NumberFormat(locale, {
      style: "unit",
      unit: "hour",
      unitDisplay: "long",
    });
    return formatter.format(Math.floor(timeInSeconds / (60 * 60)));
  } else if (timeInSeconds >= 60) {
    const formatter = new Intl.NumberFormat(locale, {
      style: "unit",
      unit: "minute",
      unitDisplay: "long",
    });
    return formatter.format(Math.floor(timeInSeconds / 60));
  } else {
    const formatter = new Intl.NumberFormat(locale, {
      style: "unit",
      unit: "second",
      unitDisplay: "long",
    });
    return formatter.format(timeInSeconds);
  }
}
