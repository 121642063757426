import { createBrowserHistory } from "history";
import React, { Suspense, createContext, useState, useEffect, useMemo } from "react";
import { Route } from "./Route";
import { match } from "path-to-regexp";

interface RouterProps {
  children: React.ReactElement | React.ReactElement[];
  fallback: JSX.Element;
}

export const RouterContext = createContext(null as null | any);

export const history = createBrowserHistory();

export const Router = (props: RouterProps) => {
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);

  useEffect(() => {
    return history.listen(e => setCurrentUrl(s => (s !== e.location.pathname ? e.location.pathname : s)));
  }, []);

  const matches = useMemo(() => {
    const children = Array.isArray(props.children)
      ? props.children.filter((c: React.ReactElement) => c.type === Route)
      : [props.children.type === Route ? props.children : null];
    const matches = children.map((c: any) => {
      return { child: c, fc: match(c.props.path) };
    });
    return matches;
  }, [props.children]);

  for (const m of matches) {
    const foundMatch = m.fc(currentUrl);
    if (foundMatch) {
      return <RouterContext.Provider value={foundMatch.params}>{m.child}</RouterContext.Provider>;
    }
  }

  return <Suspense fallback={"Loading..."}>{props.fallback}</Suspense>;
};
