import { Box, Button, CardContent, Checkbox, Typography, alpha } from "@mui/material";
import { useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Dot from "./Dot";
import ButtonChangeStatus from "./Buttons/ButtonChangeStatus";
import useService from "../hooks/useService";
import { useRecoilValue } from "recoil";
import { TrafficChartData, useApi } from "@blindspot/bspot-api-lib";
import { apiClient } from "../state/atoms";
import useReportError from "../hooks/useReportError";
import { IPv, NetworkUnit, Period } from "@blindspot/bspot-api-lib";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { formatPackets } from "../utils/formatPackets";
import { Interval } from "./TrafficGraph";
import { formatBits } from "../utils/formatBits";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { graphic } from "echarts";
import { GraphNoDataBadge } from "./GraphNoDataBadge";

export type Time = "10 MIN" | "HOUR" | "DAY" | "WEEK" | "MONTH" | Array<Interval>;

export function timeToPeriod(time: Time): Period {
  return time === "10 MIN"
    ? "minute"
    : time === "HOUR"
    ? "hour"
    : time === "DAY"
    ? "day"
    : time === "WEEK"
    ? "week"
    : "month";
}

function TrafficGraph({
  option,
  onBrushSelected,
  onEvents,
}: {
  option: EChartsOption | undefined;
  onBrushSelected: (params: any) => void;
  onEvents: Record<string, (params: any) => void>;
}) {
  const echartInstance = useRef<ReactECharts | null>(null);

  useEffect(() => {
    const instance = echartInstance.current?.getEchartsInstance();

    instance?.dispatchAction({
      type: "takeGlobalCursor",
      key: "brush",
      brushOption: {
        brushType: "lineX",
      },
    });
  }, [option]);

  return (
    <ReactECharts
      ref={e => {
        echartInstance.current = e;
      }}
      style={{ height: "100%", width: "100%" }}
      option={option}
      onEvents={Object.assign(onEvents, { brushEnd: onBrushSelected })}
    />
  );
}

function maxTraffic(traffic: TrafficChartData[] | undefined, spMaxTraffic: number) {
  if (!traffic) return;
  const maxClean = traffic[0].data.reduce((acc, v) => (acc < v ? v : acc), 0);
  const maxAttack = traffic[0].data.reduce((acc, v) => (acc < v ? v : acc), 0);
  const max = maxAttack > maxClean ? maxAttack : maxClean;
  return spMaxTraffic > max ? spMaxTraffic : undefined;
}
interface TrafficGraphProps {
  IPversion: IPv;
  interval: Interval;
  backZoom: () => void;
  time: Time;
  setTime: Dispatch<SetStateAction<Time>>;
}

function setRange(arr: Time, from: Date, to: Date) {
  return arr instanceof Array
    ? [
        ...arr,
        {
          from: from,
          to: to,
        } as Interval,
      ]
    : [
        {
          from: from,
          to: to,
        } as Interval,
      ];
}

function rangeCreater(arr: Time, traffic: TrafficChartData[], params: any) {
  const from = new Date(traffic[0].labels[params.areas[0].coordRange[0]]);
  const to = new Date(traffic[0].labels[params.areas[0].coordRange[1]]);

  const min_count_of_points = 60;

  if (to.getTime() - from.getTime() > min_count_of_points * 1000) {
    return setRange(arr, from, to);
  } else {
    const offset = (min_count_of_points * 1000 - (to.getTime() - from.getTime())) / 2;

    if (new Date().getTime() - to.getTime() < offset * 10) {
      from.setMilliseconds(from.getMilliseconds() - offset * 2);
      return setRange(arr, from, to);
    } else {
      to.setMilliseconds(to.getMilliseconds() + offset);
      from.setMilliseconds(from.getMilliseconds() - offset);
      return setRange(arr, from, to);
    }
  }
}

export default function TrafficGraphLine({ IPversion, time, backZoom, interval, setTime }: TrafficGraphProps) {
  const theme = useTheme();
  const { t, i18n } = useTranslation("index", { keyPrefix: "traffic_graph" });

  const [units, setUnits] = useState<NetworkUnit>("l3bps");
  const [bandwidth, setBandwidth] = useState<boolean>(false);

  const onBrushSelected = (params: any) => {
    if (traffic && params.areas.lenght !== 0 && params.areas[0] && params.areas[0].coordRange.lenght !== 0) {
      setTime(arr => rangeCreater(arr, traffic, params));
    }
  };

  const service = useService();
  const api = useRecoilValue(apiClient);
  const [traffic, error, loading] = useApi(
    [api.get_traffic(), { service, ipv: IPversion, units, interval: "custom", start: interval.from, end: interval.to }],
    { stale: true }
  );
  const [serviceData, errorService] = useApi([api.get_service(), service]);
  useReportError([errorService, error], ["get_tenant", "get_traffic"]);

  if (error) {
    if (error?.status_code === 204 && traffic === undefined) {
      return (
        <CardContent sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <GraphNoDataBadge title={t("no_data_title")} subtitle={t("no_data_subtitle")} />
        </CardContent>
      );
    } else if (traffic === undefined) {
      return (
        <CardContent sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <GraphNoDataBadge title={t("failed_data_load")} />
        </CardContent>
      );
    }
  }

  const onEvents: Record<string, (params: any) => void> = {};

  const option = (() => {
    return (
      traffic &&
      serviceData &&
      traffic.length > 0 && {
        animation: false,
        backgroundColor: "transparent",
        toolbox: {
          show: false,
        },
        grid: {
          left: "15%",
          top: "5%",
          right: "3.5%",
          bottom: "10%",
        },
        brush: {
          xAxisIndex: "all",
        },
        tooltip: {
          lineStyle: {
            color: "gradient",
            curveness: 5,
          },
          confine: true,
          trigger: "axis",
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          formatter: function (params: any) {
            if (params instanceof Array) {
              if (params.length) {
                let message = "";
                message += `<span style="color: ${theme.palette.text.primary}; text-shadow: 0px 0px 3px ${
                  theme.palette.background.paper
                };">${new Date(params[0].axisValueLabel).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  second: "numeric",
                })}`;
                params.forEach(param => {
                  message +=
                    units === "l3bps"
                      ? `<br/>${param.marker}${param.seriesName}: ${formatBits(param.value, i18n.language, true)}${
                          "/s" || ""
                        }`
                      : `<br/>${param.marker}${param.seriesName}: ${formatBits(param.value, i18n.language, true)}${
                          "/s" || ""
                        }`;
                });
                message += "</span>";
                return message;
              } else {
                return null;
              }
            } else {
              let message = "";
              message += `<span style="color: ${new Date(params[0].axisValueLabel).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                second: "numeric",
              })}`;
              message +=
                units === "l3bps"
                  ? `<br/>${params.marker}${params.seriesName}: ${formatBits(params.value, i18n.language, true)}${
                      "/s" || ""
                    }`
                  : `<br/>${params.marker}${params.seriesName}: ${formatBits(params.value, i18n.language, true)}${
                      "/s" || ""
                    }`;
              message += "</span>";
              return message;
            }
          },
        },
        xAxis: {
          type: "category",
          showGrid: true,
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: theme.palette.divider,
            },
          },
          data: traffic[0].labels,
          axisLine: {
            lineStyle: {
              color: "transparent",
            },
          },
          axisLabel: {
            formatter: (label: string) =>
              new Date(traffic[0].labels[traffic[0].labels.length - 1]).getTime() -
                new Date(traffic[0].labels[0]).getTime() <
              24 * 60 * 60 * 1000
                ? new Date(label).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                : new Date(label).toLocaleDateString("hsb-DE"),
            color: theme.palette.text.secondary,
          },
        },
        yAxis: {
          min: 0,
          boundaryGap: ["20%", "20%"],
          max:
            (units === "l3bps" && bandwidth && maxTraffic(traffic, serviceData.sp_max_clean_traffic * 1_000_000)) ||
            undefined,
          axisLabel: {
            color: theme.palette.text.secondary,
            formatter: (x: number) =>
              `${units === "l3bps" ? formatBits(x, i18n.language, true) : formatPackets(x, i18n.language, true)}/s`,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: theme.palette.divider,
            },
          },
        },
        series: [
          {
            name: "Attack",
            type: "line",
            color: theme.palette.error.main,
            symbolSize: 0,
            data: traffic[0].data,
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: theme.palette.error.main,
                },
                {
                  offset: 1,
                  color: alpha(theme.palette.error.main, 0.1),
                },
              ]),
            },
            markLine:
              {
                symbol: ["none", "none"],
                silent: true,
                data: [
                  {
                    name: "Your bandwidth limit",
                    show: false,
                    yAxis:
                      (units === "l3bps" &&
                        bandwidth &&
                        (serviceData ? serviceData.sp_max_clean_traffic * 1_000_000 : undefined)) ||
                      -1,
                    label: {
                      normal: {
                        show: true,
                        position: "insideStartTop",
                        formatter: t("bandwidth_limit"),
                        backgroundColor: "transparent",
                        color: theme.palette.text.disabled,
                      },
                    },
                    lineStyle: {
                      normal: {
                        color: theme.palette.text.disabled,
                        width: 2,
                      },
                    },
                  },
                  {
                    label: {
                      show: false,
                    },
                    lineStyle: {
                      normal: {
                        type: "solid",
                        color: theme.palette.divider,
                        width: 1,
                      },
                    },
                    xAxis: traffic[0].labels[traffic[0].labels.length - 1],
                  },
                ],
              } || undefined,
          },
          {
            name: "Clean",
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: theme.palette.success.main,
                },
                {
                  offset: 1,
                  color: alpha(theme.palette.success.main, 0.1),
                },
              ]),
            },
            color: theme.palette.mode === "dark" ? theme.palette.success.main : "#2a5e2c",
            type: "line",
            symbolSize: 0,
            data: traffic[1].data,
          },
        ],
      }
    );
  })();

  const label = { inputProps: { "aria-label": "bandwith check box" } };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "31rem",
          width: "100%",
          padding: "0.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItem: "center", margin: "0.5rem 1rem", justifyContent: "right" }}>
          {typeof time !== "string" && (
            <Box sx={{ my: "auto", flexGrow: 1, marginRight: "0.5rem" }}>
              <Button
                onClick={backZoom}
                variant="outlined"
                sx={{
                  display: { xs: "inline-flex", xxl: "none" },
                }}
              >
                {/* <ChevronLeftIcon sx={{ color: theme.palette.text.secondary }} /> */}
                Back
              </Button>
            </Box>
          )}
          <Box
            sx={{ display: "flex", alignItem: "center", justifyContent: "right", width: "100%", height: "2.625rem" }}
          >
            <Box sx={{ display: "flex", alignItem: "center", justifyContent: "left" }}>
              <Box sx={{ my: "auto" }}>
                <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                  {t("show_bandwidth_limit")}
                </Typography>
              </Box>
              <Checkbox
                disabled={units !== "l3bps"}
                sx={{ marginLeft: "0.25rem", marginRight: "1rem" }}
                checked={bandwidth}
                onChange={x => setBandwidth(x.target.checked)}
                {...label}
              />
            </Box>
            <Box sx={{ my: "auto" }}>
              <ButtonChangeStatus
                sx={{ height: "2rem" }}
                onChange={e => setUnits(e === "bps" ? "l3bps" : "pps")}
                currentName={units === "l3bps" ? "bps" : "pps"}
                leftName={"bps"}
                rightName={"pps"}
              />
            </Box>
          </Box>
        </Box>
        {(traffic && (
          <>
            <Box
              sx={{ position: "relative", display: "flex", height: "100%", flexDirection: "column" }}
              data-testid={"trafficGraph"}
            >
              <Box
                sx={{
                  position: "absolute",
                  display: loading ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
              {!error && option && traffic.length > 0 && (
                <TrafficGraph option={option} onBrushSelected={onBrushSelected} onEvents={onEvents} />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "center",
                  paddingTop: "1rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Dot status={"ok"} />
                  <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {t("clean_traffic")}:{" "}
                    {units === "l3bps"
                      ? formatBits(traffic[1].average, i18n.language, true)
                      : formatPackets(traffic[1].average, i18n.language, true)}
                    /s
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Dot status={"err"} />
                  <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                    {t("attack")}:{" "}
                    {units === "l3bps"
                      ? formatBits(traffic[0].average, i18n.language, true)
                      : formatPackets(traffic[0].average, i18n.language, true)}
                    /s
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                {t("total")}:{" "}
                {units === "l3bps"
                  ? formatBits(traffic[2].average, i18n.language, true)
                  : formatPackets(traffic[2].average, i18n.language, true)}
                /s
              </Typography>
            </Box>
          </>
        )) || (
          <Box sx={{ zIndex: 2, height: "100%", display: "flex", justifyContent: "center" }}>
            <Box sx={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <CircularProgress />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
