import { Button, ButtonGroup, SxProps, Typography } from "@mui/material";

interface ButtonChangeStatusProps<L extends string, R extends string, A extends string> {
  currentName: string;
  leftName: L;
  rightName: R;
  all: A;
  sx?: SxProps;
  onChange: (value: L | R | A) => void;
}

export default function ButtonIPSwitch<L extends string, R extends string, A extends string>({
  currentName,
  leftName,
  rightName,
  all,
  sx,
  onChange,
}: ButtonChangeStatusProps<L, R, A>) {
  return (
    <ButtonGroup sx={sx}>
      <Button onClick={() => onChange(all)} variant={currentName === all ? "contained" : "outlined"}>
        IP<Typography sx={{ textTransform: "lowercase" }}>v4+v6</Typography>
      </Button>
      <Button onClick={() => onChange(leftName)} variant={currentName === leftName ? "contained" : "outlined"}>
        IP<Typography sx={{ textTransform: "lowercase" }}>{leftName.split("ip")}</Typography>
      </Button>
      <Button onClick={() => onChange(rightName)} variant={currentName === rightName ? "contained" : "outlined"}>
        IP<Typography sx={{ textTransform: "lowercase" }}>{rightName.split("ip")}</Typography>
      </Button>
    </ButtonGroup>
  );
}
