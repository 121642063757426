import { Box, Typography, useTheme } from "@mui/material";

interface GraphNoDataBadgeProps {
  title: string;
  subtitle?: string;
}
export function GraphNoDataBadge(props: GraphNoDataBadgeProps) {
  const theme = useTheme();
  return (
    <Box sx={{ display: "grid", placeItems: "center" }}>
      <svg width="92" height="91" viewBox="0 0 92 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="46" cy="45" r="38" fill={theme.palette.action.disabledBackground} />
        <path
          d="M46 5.61867e-06C52.0408 5.35462e-06 58.0225 1.1769 63.6034 3.46349C69.1844 5.75007 74.2554 9.10158 78.5269 13.3266C82.7984 17.5517 86.1867 22.5676 88.4985 28.0879C90.8102 33.6082 92 39.5249 92 45.5L46 45.5L46 5.61867e-06Z"
          fill={theme.palette.action.active}
        />
      </svg>
      <Typography variant="subtitle1">{props.title}</Typography>
      {props.subtitle && <Typography variant="caption">{props.subtitle}</Typography>}
    </Box>
  );
}
