import { useEffect } from "react";
import { trackGaEvent } from "../utils/trackGaEvent";

interface ErrorReporterProps {
  children: React.ReactNode;
}

export default function ErrorReporter({ children }: ErrorReporterProps) {
  useEffect(() => {
    const handleError = async (event: ErrorEvent) => {
      console.log(event);

      const payload = {
        timestamp: event.timeStamp,
        errorMessage: event.error.message,
        errorStack: event.error.stack,
        url: window.location.href,
        agentData: window.navigator.appVersion,
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
        product: window.navigator.product,
      };

      trackGaEvent("custom_error", payload);

      // await fetch("http://localhost:8080/", {
      //   method: "POST",
      //   body: JSON.stringify(payload),
      //   headers: {
      //     "Content-Type": "application/JSON",
      //   },
      // });
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return <>{children}</>;
}
