import { openID } from "@blindspot/bspot-api-lib/lib";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { jwt as jwtState } from "../state/atoms";
import { clearUrlParams, getParam } from "../utils/params";
import { AUTH_REALM, AUTH_URI, CLIENT_ID, REDIRECT_URI } from "../consts";
import { CodeChallenge, CodeVerifier } from "@blindspot/bspot-api-lib/lib/openid";

interface OpenIDGuardProps {
  children: JSX.Element | JSX.Element[];
}

export async function login() {
  localStorage.setItem("1", new CodeVerifier().get());
  localStorage.setItem("2", new CodeVerifier().get());
  localStorage.setItem("3", new CodeVerifier().get());
  localStorage.setItem("4", new CodeVerifier().get());
  const localStorageKey = Math.floor(Math.random() * (4 - 1 + 1) + 1).toString();
  location.href = await openID.login_url(
    {
      url: AUTH_URI,
      realm: AUTH_REALM,
    },
    CLIENT_ID,
    REDIRECT_URI,
    new CodeChallenge(CodeVerifier.fromSaved(localStorage.getItem(localStorageKey) as string)),
    [],
    localStorageKey
  );
}

const Inner = React.memo(
  function Inner({ jwt, children }: { jwt: string | null; children: JSX.Element[] | JSX.Element }) {
    return jwt ? <>{children}</> : null;
  },
  (prevProps, nextProps) => {
    return !(prevProps.jwt === null && nextProps.jwt !== null);
  }
);

export default function OpenIDGuard({ children }: OpenIDGuardProps) {
  const [jwt, setJwt] = useRecoilState(jwtState);

  useEffect(() => {
    (async () => {
      const code = getParam("code");
      if (code) {
        const localStorageKey: string = JSON.parse(getParam("state") || "");
        clearUrlParams();
        const code_verifier = CodeVerifier.fromSaved(localStorage.getItem(localStorageKey) || "");
        const token = await openID.token(
          {
            url: AUTH_URI,
            realm: AUTH_REALM,
          },
          code,
          REDIRECT_URI,
          CLIENT_ID,
          code_verifier
        );

        token
          .map(res => {
            let refreshToken = res.refresh_token;
            setJwt(res.access_token);
            setInterval(async () => {
              const refresh = await openID.refresh_token(
                {
                  url: AUTH_URI,
                  realm: AUTH_REALM,
                },
                CLIENT_ID,
                refreshToken || "missing"
              );
              refresh.map(res => {
                setJwt(res.access_token);
                refreshToken = res.refresh_token;
              });
            }, (res.expires_in - 15) * 1000);
          })
          .map_err(err => {
            console.error("Failed", err);
          });
      } else {
        await login();
      }
    })();
  }, []);

  return <Inner jwt={jwt}>{children}</Inner>;
}
