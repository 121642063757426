import React from "react";

import Index from "./pages/index/Index";
import { Box, Container, CssBaseline, useTheme } from "@mui/material";
import AppBar from "./components/AppBar";
import { Router } from "./providers/router/Router";
import { Route } from "./providers/router/Route";
import { usePrintBackground } from "./hooks/usePrintBackground";
import PrintLayout from "./components/Print/PrintLayout";
import * as Sentry from "@sentry/react";

const CreateReport = React.lazy(() => import("./pages/create_report/CreateReport"));
const AllIpPrefixes = React.lazy(() => import("./pages/all_ip_prefixes/AllIpPrefixes"));
const TrafficDetails = React.lazy(() => import("./pages/traffic_details/TrafficDetails"));
const AttackTester = React.lazy(() => import("./pages/attack_tester/AttackTester"));
const ConnectionLog = React.lazy(() => import("./pages/connection_log/ConnectionLog"));
const IPPrefixesLog = React.lazy(() => import("./pages/ip_prefixes_log/BGPPrefixesLog"));
const AttackDetail = React.lazy(() => import("./pages/attack_detail/AttackDetail"));
const Settings = React.lazy(() => import("./pages/settings/Settings"));
const Notifications = React.lazy(() => import("./pages/notifications/Notifications"));
const AttackLog = React.lazy(() => import("./pages/attack_log/AttackLog"));
const NotFoundPage = React.lazy(() => import("./components/NotFoundPage"));
const Attacks = React.lazy(() => import("./pages/attacks/Attacks"));

export default function Main() {
  const theme = useTheme();
  usePrintBackground(theme);

  const scope = Sentry.getCurrentScope();
  scope.setTag("theme.mode", theme.palette.mode);

  return (
    <Box sx={{ display: "flex", WebkitPrintColorAdjust: "exact", printColorAdjust: "exact" }}>
      <CssBaseline />
      <AppBar />
      <Container maxWidth={"xl"}>
        <PrintLayout>
          <Router fallback={<NotFoundPage />}>
            <Route path="/" component={Index} />
            <Route path="/create-report" component={CreateReport} />
            <Route path="/attack-detail/:id" component={AttackDetail} />
            <Route path="/attacks/:id" component={Attacks} />
            <Route path="/all-ip-prefixes" component={AllIpPrefixes} />
            <Route path="/traffic-details" component={TrafficDetails} />
            <Route path="/attack-tester" component={AttackTester} />
            <Route path="/connection-log" component={ConnectionLog} />
            <Route path="/bgp-prefixes-log" component={IPPrefixesLog} />
            <Route path="/settings/:page" component={Settings} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/attack_log" component={AttackLog} />
          </Router>
        </PrintLayout>
      </Container>
    </Box>
  );
}
