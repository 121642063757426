import { Box, Link, SxProps, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";

interface PrefixStatusProps {
  statusName: string;
  status: string;
  sx?: SxProps;
}
const prefixStatus = {
  accepted: "Accepted",
  review: "Under review",
  denied: "Denied",
  propagated: "Propagated",
  receiving: "Receiving",
  notreceiving: "Not Receiving",
};

export default function PrefixStatus({ statusName, status, sx }: PrefixStatusProps) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          ...sx,
        }}
      >
        {status === prefixStatus.accepted && (
          <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", marginRight: "10px" }} />
        )}
        {status === prefixStatus.review && (
          <WarningIcon color="warning" sx={{ width: "20px", height: "20px", marginRight: "10px" }} />
        )}
        {status === prefixStatus.denied && (
          <ErrorIcon color="error" sx={{ width: "20px", height: "20px", marginRight: "10px" }} />
        )}
        {status === prefixStatus.propagated && (
          <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", marginRight: "10px" }} />
        )}

        {status === prefixStatus.receiving && (
          <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", marginRight: "10px" }} />
        )}
        {status === prefixStatus.notreceiving && (
          <Tooltip
            arrow
            title={
              <>
                <Typography>
                  Sflow samples are not <br /> coming, more information <br />
                  <Link>here</Link>
                </Typography>
              </>
            }
          >
            <ErrorIcon color="error" sx={{ width: "20px", height: "20px", marginRight: "10px" }} />
          </Tooltip>
        )}

        <Typography variant="subtitle2">
          {statusName}
        </Typography>
      </Box>
    </>
  );
}
