import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";
import { Attack } from "@blindspot/bspot-api-lib";

interface AttackHistoryErrCardProps {
  attacks: Attack[];
}

export default function AttackHistoryErrCard({ attacks }: AttackHistoryErrCardProps) {
  const { t } = useTranslation("index", { keyPrefix: "cards" });
  const loading = false;

  const ipCount = new Set();
  attacks.forEach(x =>
    x.data.destination_ips?.forEach(x => {
      ipCount.add(x);
    })
  );

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.destination.title")}
      h5={`${ipCount.size} ${t("under_attack.destination.destination", { count: ipCount.size })}`}
      icon={<WarningAmberOutlinedIcon color="error" fontSize="large" sx={{ alignSelf: "flex-start" }} />}
    >
      <CustomCardAction linkToIdElement={{ text: t("common.show_all"), href: "#mitigations", color: "text.primary" }} />
    </TopCard>
  );
}
