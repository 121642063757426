import { SxProps, Box, useTheme, Typography } from "@mui/material";
import Dot from "./Dot";

interface DotStatusPrefixProps {
  statusName: string;
  status: "ok" | "err" | "warn";
  sx?: SxProps;
}

export default function DotStatusPrefix({ statusName, status, sx }: DotStatusPrefixProps) {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
          ...sx,
        }}
      >
        <Dot status={status} />
        <Typography variant="body1" marginLeft={"0.5rem"} sx={{ color: theme.palette.text.secondary }}>
          {statusName}
        </Typography>
      </Box>
    </>
  );
}
