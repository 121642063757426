import ReactECharts, { EChartsOption } from "echarts-for-react";

interface LittleCardChartProps {
  data: number[];
  color: string;
}

export default function LittleCardChart({ data, color }: LittleCardChartProps) {
  const options: EChartsOption = {
    grid: {
      left: "0px",
      right: "0px",
    },
    xAxis: {
      type: "category",
      show: false,
    },
    yAxis: {
      type: "category",
      show: false,
    },
    series: [
      {
        silent: true,
        data: data,
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: { color: color, width: 3 },
      },
    ],
  };

  return <ReactECharts style={{ height: "100%", width: "100%" }} option={options} />;
}
