import { Box } from "@mui/material";
import React from "react";

interface Props {
  idTarget: string;
  height: number;
}

export default function AnchorGap({ idTarget, height }: Props) {
  return <Box id={idTarget} sx={{ visibility: "hidden", height: height, marginTop: `-${height}px` }} />;
}
