import PrintHeader from "./PrintHeader";
import PrintFooter from "./PrintFooter";
import React from "react";

export default function PrintLayout({ children }: { children: JSX.Element }) {
  return (
    <table className="print-layout">
      <thead>
        <tr>
          <th>
            <PrintHeader />
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td>
            <PrintFooter />
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td>{children}</td>
        </tr>
      </tbody>
    </table>
  );
}
