export const formatBits = (bits: number, locale: Intl.UnicodeBCP47LocaleIdentifier, short?: boolean) => {
  const units = ["bits", "Kbit", "Mbit", "Gbit"];
  const units_short = ["bit", "Kbit", "Mbit", "Gbit"];
  let index = 0;
  const singular = "bit";

  while (bits >= 100000 && index < units.length - 1) {
    bits /= 1000;
    index++;
  }

  const finalValue = Math.max(0, Math.floor(bits)).toLocaleString(locale);

  if (short) return finalValue + " " + units_short[index];
  if (bits === 1) return "1 " + singular;
  return finalValue + " " + units[index];
};
