export const getCookie = (key: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  return parts.length === 2 ? (parts.pop() || "").split(";").shift(): undefined;
};

export const setCookie = (key: string, val: string, time: number) => {
  const date = new Date();
  const value = val;

  //(1 * 24 * 60 * 60 * 1000)
  date.setTime(date.getTime() + time);

  document.cookie = key+"="+value+"; expires="+date.toUTCString()+"; path=/";
};

export const deleteCookie = (key: string) => {
  const date = new Date();

  // Set it expire in -1 day
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  document.cookie = key+"=; expires="+date.toUTCString()+"; path=/";
};