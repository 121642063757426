import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { appThemeMode } from "../state/atoms";
import Pagination from "./Pagination";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

export interface BarGraphCardProps {
  title: string;
  rows: { percent: number; title: string }[];
  rowsPerPage: number;
  colorBar: string;
  borderless?: boolean;
  search?: (s: string, compare: string) => boolean;
  scrollableHeight?: string;
  sxAction?: SxProps;
  minHeight?: string;
  children?: JSX.Element | JSX.Element[];
}
interface LoadingProps {
  title: string;
  borderless?: boolean;
  rowsPerPage?: number;
}

function Loading({ title, borderless, rowsPerPage }: LoadingProps) {
  const theme = useTheme();
  const dummyRows = new Array(rowsPerPage || 3).fill("");
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: borderless ? 0 : 1,
        borderColor: theme.palette.divider,
      }}
    >
      <CardHeader
        sx={{ borderBottom: 1, borderColor: "divider" }}
        title={title}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent sx={{ height: "100%", padding: 0, paddingBottom: "1rem !important" }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          {dummyRows.map((row, key) => (
            <Box key={key} sx={{ width: "100%", height: "56.02px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ margin: "1rem" }}>
                  <Skeleton>
                    <Typography variant="body2">000.000.000.00</Typography>
                  </Skeleton>
                </Box>
              </Box>
              <Box sx={{ width: "100%", paddingX: "1rem", height: "4px" }}>
                <Skeleton sx={{ height: "7px", marginY: "-1.5px", borderRadius: "4px" }} />
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
      <Box sx={{ height: "3.25rem" }}></Box>
    </Card>
  );
}

BarGraphCard.Loading = Loading;

interface ErrorProps {
  title: string;
  borderless?: boolean;
  errorStatusCode?: number;
  errorTitle?: string;
  errorSubtitle?: string;
}

function Error({ title, borderless, errorStatusCode, errorTitle, errorSubtitle }: ErrorProps) {
  const theme = useTheme();
  const { t } = useTranslation("components", { keyPrefix: "bar_graph_card" });

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: borderless ? 0 : 1,
        borderColor: theme.palette.divider,
      }}
    >
      <CardHeader
        sx={{ borderBottom: 1, borderColor: "divider" }}
        title={title}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent sx={{ height: "100%", padding: 0, paddingTop: "1rem !important", paddingBottom: "1rem !important" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
            p: "1rem",
            height: "100%",
          }}
        >
          <svg width="70" height="60" viewBox="0 0 70 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="70" height="60" rx="2" fill={theme.palette.action.disabledBackground} />
            <rect x="5.8335" y="7.9248" width="18.6667" height="4.5283" rx="2" fill={theme.palette.action.focus} />
            <rect x="5.8335" y="23.7739" width="18.6667" height="4.5283" rx="2" fill={theme.palette.action.focus} />
            <rect x="5.8335" y="39.623" width="18.6667" height="4.5283" rx="2" fill={theme.palette.action.focus} />
            <rect x="54.8333" y="7.9248" width="9.33333" height="4.5283" rx="2" fill={theme.palette.action.focus} />
            <rect x="54.8333" y="23.7739" width="9.33333" height="4.5283" rx="2" fill={theme.palette.action.focus} />
            <rect x="54.8333" y="39.623" width="9.33333" height="4.5283" rx="2" fill={theme.palette.action.focus} />
            <rect x="5.8335" y="15.8496" width="58.3333" height="4.5283" rx="2" fill={theme.palette.action.disabled} />
            <rect x="5.8335" y="31.6982" width="58.3333" height="4.5283" rx="2" fill={theme.palette.action.disabled} />
            <rect x="5.8335" y="47.5474" width="58.3333" height="4.5283" rx="2" fill={theme.palette.action.disabled} />
            <rect x="5.8335" y="15.8496" width="11.6667" height="4.5283" rx="2" fill={theme.palette.action.active} />
            <rect x="5.8335" y="31.6982" width="50.1667" height="4.5283" rx="2" fill={theme.palette.action.active} />
            <rect x="5.8335" y="47.5474" width="36.1667" height="4.5283" rx="2" fill={theme.palette.action.active} />
          </svg>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1">
              {errorStatusCode === 204 ? (errorTitle ? errorTitle : t("no_data")) : t("failed_data_load")}
            </Typography>
            {errorSubtitle && <Typography variant="caption">{errorSubtitle}</Typography>}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

BarGraphCard.Error = Error;

function BarGraphCard({
  rows,
  rowsPerPage,
  title,
  colorBar,
  borderless,
  children,
  search,
  scrollableHeight,
  minHeight,
  sxAction,
}: BarGraphCardProps) {
  const { t } = useTranslation("attacks", { keyPrefix: "attacks_source" });
  const theme = useTheme();
  const [mode] = useRecoilState(appThemeMode);
  const [filter, setFilter] = useState("");

  const [page, setPage] = useState(0);

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: borderless ? 0 : 1,
        borderColor: theme.palette.divider,
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "h6" }}
        action={
          search && (
            <TextField
              size="small"
              id="outlined-basic"
              label={t("search")}
              variant="outlined"
              onChange={e => setFilter(e.target.value)}
              value={filter}
            />
          )
        }
        sx={{ borderBottom: 1, borderColor: "divider" }}
      />
      <CardContent
        sx={{
          height: "100%",
          padding: 0,
          paddingBottom: "1rem !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            minHeight: minHeight || "initial",
            maxHeight: scrollableHeight,
            overflow: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {rows.length == 0 ? (
            <Typography textAlign="center" padding="2rem 0 1rem">
              {t("no_data")}
            </Typography>
          ) : (
            rows
              .filter(v => (search ? search(v.title, filter) : true))
              .filter((_, key) => key >= page * rowsPerPage && key < (page + 1) * rowsPerPage)
              .map((row, key) => (
                <Box key={key} sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        margin: "1rem 0 1rem 1rem",
                        maxWidth: "80%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                      >
                        {row.title}
                      </Typography>
                    </Box>
                    <Box sx={{ margin: "1rem" }}>
                      <Typography variant="body2">{row.percent + "%"}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%", paddingX: "1rem" }}>
                    <Box
                      sx={{
                        WebkitPrintColorAdjust: "exact",
                        printColorAdjust: "exact",
                        width: "100%",
                        backgroundColor: mode === "dark" ? theme.palette.action.selected : "rgba(0, 0, 0, 0.08)",
                        borderRadius: "4px",
                      }}
                    >
                      <Box
                        sx={{
                          WebkitPrintColorAdjust: "exact",
                          printColorAdjust: "exact",
                          width: row.percent + "%",
                          height: "4px",
                          backgroundColor: colorBar,
                          borderRadius: "4px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))
          )}
        </Box>
      </CardContent>
      {rows.length > 0 && (
        <CardActions sx={{ padding: "1rem 1.5rem", display: "flex", justifyContent: "flex-end", ...sxAction }}>
          {children !== undefined ? (
            children
          ) : (
            <Pagination
              sx={{ "@media print": { display: "none" } }}
              rowsPerPage={rowsPerPage}
              pageCount={rows.length}
              page={page}
              setPage={setPage}
            />
          )}
        </CardActions>
      )}
    </Card>
  );
}

export default BarGraphCard;
