import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";

import * as Sentry from "@sentry/react";
import { API_BASE_URI, RELEASE, SENTRY_DSN } from "./consts";

import { LicenseInfo } from "@mui/x-license";
import { MUI_LICENSE_KEY } from "./consts";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

Sentry.init({
  dsn: SENTRY_DSN,
  tunnel: "/sentun",
  enabled: !!SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: RELEASE,
  tracePropagationTargets: [
    API_BASE_URI,
    ...(window.location.origin.includes("localhost") ? [] : [window.location.origin]),
  ],
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // Sentry.feedbackIntegration({
    //   autoInject: false,
    //   colorScheme: "system",
    // }),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
