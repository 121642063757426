import { Box, Typography } from "@mui/material";
import Link from "../../providers/router/Link";
import ErrorIcon from "@mui/icons-material/Error";
import AnchorGap from "../../components/AnchorGap";
import { useTranslation } from "react-i18next";
import { Attack } from "@blindspot/bspot-api-lib";
import { formatBytes } from "../../utils/formatBytes";
import getNearestTimeUnit from "../../utils/getNearestTimeUnit";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataList from "../../components/Table/DataList";
import BlindspotTable from "../../components/BlindspotTable";

interface AttacklogErrProps {
  attacks: Attack[];
  error: boolean;
}

export default function AttacklogErr({ attacks, error }: AttacklogErrProps) {
  const { t, i18n } = useTranslation("index", { keyPrefix: "attack_log_err" });

  const columns: GridColDef[] = [
    {
      headerName: t("mitigation_id"),
      field: "attack_id",
      flex: 1,
      cellClassName: "wrap",
      renderCell: ({ row }) => (
        <Link underline={"always"} href={`/attack-detail/${row.attack_id}`}>
          #{row.attack_id.split("-").slice(-1)[0]}
        </Link>
      ),
    },
    {
      headerName: t("status"),
      field: "status",
      flex: 1,
      cellClassName: "wrap",
      renderCell: () => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ErrorIcon color={"error"} sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          <Typography marginLeft={0.75} variant="body2">
            {t("body.status")}
          </Typography>
        </Box>
      ),
    },
    {
      headerName: t("start_time"),
      field: "data.startTime",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      renderCell: ({ row }) => (
        <>
          {new Date(row.data.startTime).toLocaleDateString(i18n.language)},
          <br />
          {new Date(row.data.startTime).toLocaleTimeString(i18n.language)}
        </>
      ),
    },
    {
      headerName: t("duration"),
      field: "data.duration",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      renderCell: ({ row }) => getNearestTimeUnit(row.data.duration, i18n.language),
    },
    {
      headerName: t("destination_ips"),
      field: "data.destination_ips",
      flex: 1,
      cellClassName: "wrap",
      renderCell: ({ row }) => <DataList data={row.data.destination_ips} visibleCount={3} />,
    },
    {
      headerName: t("attack_type"),
      field: "data.type",
      flex: 1,
      cellClassName: "wrap",
      renderCell: ({ row }) => row.data.type,
    },
    {
      headerName: t("peak_bandwidth"),
      field: "data.peak_bandwith_ps.bytes",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      renderCell: ({ row }) =>
        row.data.peak_bandwidth_ps.bytes !== undefined
          ? `${formatBytes(row.data.peak_bandwidth_ps.bytes, i18n.language, true)}ps`
          : t("no_data"),
    },
    {
      headerName: t("actions"),
      field: "actions",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      renderCell: ({ row }) => (
        <Link underline={"always"} href={`/attack-detail/${row.attack_id}`}>
          {t("body.actions")}
        </Link>
      ),
    },
  ];

  return (
    <>
      <AnchorGap idTarget="mitigations" height={50} />
      <BlindspotTable
        sx={{
          marginTop: "1.5rem",
        }}
        rowHeight={92}
        rowsPerPage={attacks.length <= 3 ? attacks.length : 3}
        rows={attacks}
        columns={columns}
        getRowId={attacks => attacks.attack_id}
        error={error ? t("error") : undefined}
      />
    </>
  );
}
