import { Box, Typography } from "@mui/material";
import Logo from "../Logo";

export default function Head() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "none",
        "@media print": { display: "flex" },
        alignItems: "center",
        justifyContent: "center",
        pageBreakAfter: "always",
      }}
    >
      <Box>
        <Logo />
        <Typography align="center" variant="h1" fontWeight="bold">
          Report
        </Typography>
      </Box>
    </Box>
  ); // Blindspot Logo uprostred
}
