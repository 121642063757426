import { Box, Link, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface GridListProps {
  data: Array<string>;
  visibleCount: number;
}
export default function DataList({ data, visibleCount }: GridListProps) {
  const { t } = useTranslation("components");

  if (data === undefined) return <>{t("table_list.no_data")}</>;

  const visibleData: string[] = data.length <= visibleCount ? data : data.slice(0, visibleCount - 1);
  const tooltipData: string[] = data.length <= visibleCount ? [] : data.slice(visibleCount - 1, data.length);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
      {visibleData.map((item, i) =>
        item.includes(":") ? (
          <Tooltip arrow title={item}>
            <Typography
              sx={{
                textDecoration: "underline dotted",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              key={i}
              variant="body2"
            >
              {item}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            key={i}
            variant="body2"
          >
            {item}
          </Typography>
        )
      )}
      {tooltipData.length > 0 && (
        <Tooltip
          arrow
          title={tooltipData.map((item, i) => (
            <Typography variant="body2" key={i}>
              {item}
            </Typography>
          ))}
        >
          <Typography variant="body2" component={Link}>
            {`+ ${tooltipData.length} ${t("table_list.more")}`}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
}
