import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import Dot from "../../components/Dot";
import Link from "../../providers/router/Link";
import Logo from "../../components/Logo";
import { useTranslation } from "react-i18next";

export default function PageTitle() {
  const theme = useTheme();
  const { t } = useTranslation("index", { keyPrefix: "title" });

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, marginY: "2rem", paddingTop: "66px", display: "flex", alignItems: "center" }}
      >
        <Logo />
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, marginLeft: "20px" }}>
          <Dot sx={{ marginRight: "0.5rem" }} status={"ok"} />
          <Typography color={theme.palette.text.secondary} variant="body1">
            {t("state_ok")}
          </Typography>
        </Box>
        <Link href="/create-report" underline="none">
          <Button variant="contained" size="medium">
            <AnalyticsOutlinedIcon sx={{ marginRight: "8px" }} />
            {t("create_report_button")}
          </Button>
        </Link>
      </Box>
    </>
  );
}
