import { Box, Typography, Button, useTheme } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DotStatusPrefix from "../DotStatusPrefix";
import Link from "../../providers/router/Link";

interface TableHeaderProps {
  tittle: string;
  description?: string | JSX.Element;
  buttonUrl?: string;
  buttonTittle?: string;
  statuses?: Array<Status>;
  marginTop?: string;
  marginBottom?: string;
}

export interface Status {
  name: string;
  state: "ok" | "err" | "warn";
  count?: number;
}

export default function TableHeader({
  tittle,
  description,
  buttonUrl,
  buttonTittle,
  statuses,
  marginTop,
  marginBottom,
}: TableHeaderProps) {
  const theme = useTheme();

  return (
    <Box sx={{ marginTop: marginTop || "6rem" }}>
      <Box
        sx={{
          marginBottom: marginBottom || "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                marginRight: "1rem",
                color: theme.palette.text.primary,
              }}
            >
              {tittle}
            </Typography>
            {statuses?.map(
              (status, i) =>
                status.count !== 0 && (
                  <DotStatusPrefix
                    sx={{ marginLeft: "0.5rem" }}
                    key={"status_" + i}
                    statusName={status.name}
                    status={status.state}
                  />
                )
            )}
          </Box>
          {typeof description === "string" ? (
            <Typography
              variant="body2"
              sx={{
                marginTop: "0.5rem",
                color: theme.palette.text.secondary,
              }}
            >
              {description}
            </Typography>
          ) : (
            <>{description}</>
          )}
        </Box>
        {buttonTittle && buttonUrl ? (
          <Link href={buttonUrl} underline="none">
            <Button
              startIcon={<FormatListBulletedIcon />}
              variant={"outlined"}
              size={"medium"}
              sx={{ alignSelf: "flex-end" }}
            >
              {buttonTittle}
            </Button>
          </Link>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
