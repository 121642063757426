import { Box, alpha } from "@mui/material";
import { useTheme } from "@mui/material";
import LittleCardChart from "./LittleCardChart";
import TopCard, { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { useApi } from "@blindspot/bspot-api-lib";
import useReportError from "../hooks/useReportError";

export default function AttackHistoryOkCard() {
  const { t, i18n } = useTranslation("index", { keyPrefix: "cards.peace.attack_history" });
  const theme = useTheme();

  const api = useRecoilValue(apiClient);
  const service = useService();
  const [data, error, loading] = useApi([api.get_attack_count(), service]);
  useReportError(error, "get_attack_count");

  return (
    <TopCard
      isLoading={loading}
      status="normal"
      overline={t("title")}
      h5={(data?.count || 0).toLocaleString(i18n.language) + "×"}
      icon={
        <Box sx={{ width: "60px", height: "60px" }}>
          <LittleCardChart color={theme.palette.error.main} data={[0, 0, 0, 4, 0, 0, 0, 2, 0, 0, 0]} />
        </Box>
      }
    >
      <CustomCardAction linkToIdElement={{ text: t("link"), href: "#attack_history", color: "primary" }} />
    </TopCard>
  );
}
