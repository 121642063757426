export function plural(num: number, singular: string | ((num: number) => string), plural: string | ((num: number) => string)): string {
  return num === 1 ? canRunFunc(num, singular) : canRunFunc(num, plural);
}

function canRunFunc(num: number, f: string | ((num: number) => string)) {
  if (typeof f === "function") {
    return f(num);
  }else{
    return f;
  }
}