import { SxProps, TablePagination } from "@mui/material";
import React from "react";
import { Dispatch, SetStateAction } from "react";

interface PaginationProps {
  sx?: SxProps;
  rowsPerPage: number;
  pageCount: number;
  page: number;
  setPage: (rpp: number) => void;
  rowsPerPageOptions?: Array<number>;
  onRowsPerPageChange?: (rpp: number) => void;
}

export default function Pagination({
  sx,
  pageCount,
  rowsPerPage,
  page,
  setPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
}: PaginationProps) {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  return (
    <TablePagination
      className="print-hide"
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "flex-end",
        ...sx,
      }}
      component="div"
      count={pageCount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={event => {
        if (onRowsPerPageChange) onRowsPerPageChange(parseInt(event.target.value, 10));
        setPage(0);
      }}
      rowsPerPageOptions={rowsPerPageOptions || []}
    />
  );
}
