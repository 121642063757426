export const formatBytes = (bytes: number, locale: Intl.UnicodeBCP47LocaleIdentifier, short?: boolean) => {
  const units = ["bytes", "KB", "MB", "GB"];
  const units_short = ["B", "KB", "MB", "GB"];
  let index = 0;
  const singular = "byte";

  while (bytes >= 100000 && index < units.length - 1) {
    bytes /= 1000;
    index++;
  }

  const finalValue = Math.max(0, Math.floor(bytes)).toLocaleString(locale);

  if (short) return finalValue + " " + units_short[index];
  if (bytes === 1) return "1 " + singular;
  return finalValue + " " + units[index];
};
