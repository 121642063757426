export function formatPackets(packets: number, locale: Intl.UnicodeBCP47LocaleIdentifier, short?: boolean) {
  const units = ["packets", "Kp", "Mp", "Gp"];
  const units_short = ["p", "Kp", "Mp", "Gp"];
  const singular = "packet";
  let index = 0;
  while (packets >= 100000 && index < units.length - 1) {
    packets /= 1000;
    index++;
  }

  const finalValue = Math.max(0, Math.floor(packets)).toLocaleString(locale);

  if (short) return finalValue + " " + units_short[index];
  if (packets === 1) return "1 " + singular;
  return finalValue + " " + units[index];
}
